import React, { useState } from "react";
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Profile from "./Profile";
import Mapping from "./Mapping";
import Exitpoint from "./Exitpoint";
import Function from "./Function";

function Dependencies({ data }) {
  const [itemVisible, setItemVisiblity] = useState(false);

  return (
    <div hidden={data.length === 0}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge bg="secondary" >{data.length} Dependencies</Badge>

      {data.map((dep) => (
        <div key={dep.jsKey}>  {itemVisible && (
          <div className='row  ms-4'>
            <Dependency data={dep} />
          </div>
        )}
        </div>
      ))}

    </div>
  )
}


function getDependencyType(dep) {
  // console.log('FORM DEP')
  // console.log(dep)
  if (dep.mapping) {
    return (
      <div className="ms-4">
        <Mapping data={dep.mapping} />
      </div>
    )
  } else if (dep.profile) {
    return (
      <div className="ms-4">
        <Profile data={dep.profile} type="Profile" />
      </div>
    )
  } else if (dep.exitpoint) {
    return (
      <div className="ms-4">
        <Exitpoint data={dep.exitpoint} type="Exitpoint" />
      </div>
    )
  } else if (dep.function) {
    return (
      <div className="ms-4">
        <Function data={dep.function} />
      </div>
    )
  }
  else {
    return (
      <Badge className="ms-2">Unknown Dependency</Badge>
    )
  }
}

function showContent(data, show) {
  if (show && data !== undefined) {
    return (
      <div>
        {getDependencyType(data)}
      </div>
    )
  }
}

export function Dependency({ data }) {
  const [itemVisible, setItemVisiblity] = useState(false);
  return (
    <div>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge bg="secondary" >{data.type}</Badge><div className="ms-2 e-badge-name text-dark">{data.name}</div>
      {showContent(data, itemVisible)}
    </div>
  )
}

export default Dependencies
