const eventBus = {
    on(event, callback) {
        // console.log('register')
      document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        // console.log('dispatch')
      document.dispatchEvent(new CustomEvent(event, {detail: data}));
    },
    remove(event, callback) {
      document.removeEventListener(event, callback);
    },
  };
  export default eventBus;