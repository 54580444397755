import isIterable from "./helper";

export default function addVariables(configuration, node, variables) {
    const nodevar = node.variables;
    if (isIterable(variables)) {
        for (const v of variables) {
            nodevar.push(v);
            linkObjects(configuration, node, v);
        }
    } else {
        nodevar.push(variables);
        linkObjects(configuration, node, variables);
    }
}

export function addVariable(variable, node, configuration) {
    linkObjects(configuration, node,variable);
}

//get the elements from the mapping value
function getElements(val) {
    const regex = /(^\/\/|xpath:\/\/|const:\/\/|function:\/\/)(.+?)(?=^\/\/|xpath:\/\/|const:\/\/|function:\/\/|$)/gi;
    const found = val.match(regex);
    if (found !== null)
        return found;
    return [];
}

//find the objects for the mapping value elements
function linkObjects(configuration, node, targetValue) {
    const targetElements = getElements(targetValue.value);
    targetElements.map((it, index0) => {
        if (it.startsWith('function://')) {
            const fname = it.replace('function://', '');
            if (fname.startsWith('mapping')) {
                const regex = /(?<=\/)(.+?)(?=\/)/ig;
                const found = fname.match(regex)
                if (found[0]) {
                    // console.log('add mapping')
                    // console.log(configuration)
                    const map = configuration.mappingNames[found[0]];
                    targetValue.mapping = map;
                }
            }
            else {
                const func = configuration.functionNames[fname];
                if (func) {
                    // console.log('add function')
                    targetValue.function = func;
                }
            }
        } else if (it.startsWith('const://')) {
            // console.log('add exitpoint')
            const value = it.replace('const://', '');
            const exitpoint = configuration.exitpointmap[value]
            if (exitpoint) {
                targetValue.exitpoint = exitpoint;
            }
        }
    })
}