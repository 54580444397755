import React, { useState } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Profile from "./Profile";
import Exitpoint from "./Exitpoint";
import BadgeExtended from "../react/BadgeExtended";
// import { getItemArray, getItemLength } from "../../helpers/helper";

export default function Deliverypoints({ data }) {
  const [delVisible, setDeliverypointVisiblity] = useState(false);

  if (data === undefined) data = []

  return (
    <div>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${delVisible ? "active" : ""}`}
        onClick={(e) => {
          setDeliverypointVisiblity((v) => !v);
        }} />
      <Badge className="badge-120" bg="secondary" >{data.length} DeliveryPoints</Badge>
      <Collapse in={delVisible}>
        <div className="ms-4" >
          {data.map((deliverypoint) => (
            <div key={deliverypoint.code}>
              {/* {delVisible && ( */}
              <Deliverypoint data={deliverypoint} />
              {/* )} */}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}

function getTransformers(dp) {
  if (dp.profile !== undefined) {
    if (dp.profile.transformers !== undefined)
      return dp.profile.transformers
  }
  return [];
}

function getProfile(profile) {
  if (profile) {
    return <Profile data={profile} type="Profile" />
  }
  return [];
}

function Deliverypoint({ data }) {
  const [delVisible, setDeliverypointVisiblity] = useState(false);

  return (
    <div >
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${delVisible ? "active" : ""}`}
        onClick={(e) => {
          setDeliverypointVisiblity((v) => !v);
        }} />
      <Badge bg="secondary" >DeliveryPoint {data.number}</Badge><Badge className="ms-2 text-dark" bg="warning" hidden={data['is-primary'] !== 'true'}>primary</Badge>
      <BadgeExtended className="ms-2" bg="success" tooltip="click to copy" action="copy">{data.conditional}</BadgeExtended>
      <Badge className="ms-2" hidden={getTransformers(data).length === 0}>{getTransformers(data).length} Transformation(s)</Badge>
      <Badge className="ms-2" bg='secondary'>Exitpoint: {data.exitpoint.name}</Badge>
      <Collapse in={delVisible}>
        <div className="ms-4" >
          {getProfile(data.profile)}
          <Exitpoint data={data.exitpoint} />
        </div>
      </Collapse>
    </div>
  )
}
