import React, { useState, useEffect } from "react";
import { Container, Navbar, Row, Col, Card, Form, Button, FormGroup, FormControl, Alert, Tabs, Tab, Modal, ProgressBar } from 'react-bootstrap';

import { buildConfiguration } from "./helpers/configurationBuilder";
import ExportDetails from "./components/export/ExportDetails";
import HomeTab from "./components/ui/HomeTab";
import { Canvas } from "./components/ui/Canvas";

import eventBus from './EventBus';

var needsToRegister = true;

function App() {
  const [showAlert, setShowAlert] = useState(false);
  const [configuration, setConfiguration] = useState(undefined);
  const [activeTab, setActtiveTab] = useState('home');
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState("Starting the parsing process...");

  function handleFile(e) {
    setShowModal(true); // Show modal when parsing starts
    setProgress(0);
    setProgressText("Loading file...");

    const newFile = e.target.files[0];
    console.log(newFile);
    if (!newFile) {
      return;
    }
    parseFile(newFile)
  }

  async function parseFile(xmlFile) {
    if (!window.DOMParser) {
      console.log('Your browser does not support DOMParser.');
    }
    const parser = new DOMParser();
    try {
      let removeJsonTextAttribute = function (value, parentElement) {
        try {
          let keyNo = Object.keys(parentElement._parent).length;
          let keyName = Object.keys(parentElement._parent)[keyNo - 1];
          parentElement._parent[keyName] = value;
        } catch (e) {
          console.error("Error: " + e);
        }
      }

      let convert = require('xml-js');
      let options = {
        compact: true,
        trim: true,
        ignoreDeclaration: true,
        ignoreInstruction: true,
        // ignoreAttributes: true,
        ignoreComment: true,
        // ignoreCdata: true,
        ignoreDoctype: true,
        nativeType: false,
        nativeTypeAttributes: false,
        // alwaysArray: true,
        arrayNotation: true,
        textFn: removeJsonTextAttribute,
      };

      var reader = new FileReader();
      reader.onload = function (e) {
        setProgress(0);
        setProgressText("Parsing XML document...");
        var content = e.target.result;

        try {
          const parsedDocument = parser.parseFromString(content, 'text/xml');
          if (parsedDocument.getElementsByTagName('parsererror').length > 0) {
            console.log('Your configuration can not be processed.');
            setShowAlert(true);
            return;
          }
          const version = parsedDocument.getElementsByTagName("configuration")[0].getAttribute('version');
        } catch (e) {
          console.log('Invalid export');
          setShowAlert(true);
          return
        }
        // console.log("HIDE ALERT")
        setShowAlert(false);

        let data = convert.xml2json(content, options);
        const json = JSON.parse(data);

        //configurationbuilder returns a Promise
        buildConfiguration(json.configuration, (progress, text, done) => {
          setTimeout(() => {
            setProgressText(text);
            setProgress(progress);
          }, 1);
        }).then((config) => {
          setTimeout(() => { setProgressText('rendering....'); }, 1);
          window.config = config;
          config.newModel = true;
          setConfiguration(config);
          setTimeout(() => { setProgressText('Flow rendering....'); }, 1);
          //send event to the bus. this is processed by the Canvas
          eventBus.dispatch("data-loaded", config);
          //when the flow rendering is done the Canvas returns an event render-flow-done
        });;

      };
      reader.readAsText(xmlFile, 'utf-8');

    } catch (err) {
      console.error(err);

    }
  }

  //create the model from the updated config
  useEffect(() => {
    // console.log('useEffect');
    if (needsToRegister) {
      needsToRegister = false;
      eventBus.on('show-flow', (data) => {
        // console.log(data);
        setActtiveTab('flow')
      });
      //all config processing is done so hide the modal
      eventBus.on('render-flow-done', (data) => {
        setTimeout(() => setShowModal(false), 1);
      });
    }
  })


  function handleTabSelect(key) {
    setActtiveTab(key);
    eventBus.dispatch("active-tab", key);
  }

  function getArrayFromHash(map) {
    var values = [];
    for (var key in map) {
      values.push(map[key]);
    }
    return values;
  }

  return (
    <div className='font-sans'>
      <Navbar bg="white" variant='light'>
        <div className='ms-4 col-3'>
          <p className="mb-0 text-lg font-bold">OT Analyzer Deluxe V2.1</p>
          <p className="text-gray-700 text-flat">It's great, it's fantastic</p>
        </div>
      </Navbar>
      <div className="grey-bar"></div>
      <Container className="ms-2" fluid>
        <FormGroup className="mt-4">
          <FormControl className="mb-2 fullwidth" type="file" onChange={handleFile} />
          <ExportDetails configuration={configuration} />
        </FormGroup>
      </Container>
      <Tabs activeKey={activeTab}
        onSelect={handleTabSelect}
        transition={false} 
      // unmountOnExit={true}
      // mountOnEnter={true}
      >
        <Tab eventKey="home" title="Home">
          <div className='ms-4 mb-1'>
            <HomeTab config={configuration} />
          </div>
        </Tab>
        <Tab eventKey="flow" title="Flow" tabClassName={!configuration ? 'd-none' : ''}>
          <Canvas />
        </Tab>
      </Tabs>
      <Alert className="m-4" variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        <Alert.Heading>You loaded an invalid Exportfile</Alert.Heading>
        <p>Please only select a valid export file or template from OpenTunnel</p>
      </Alert>
      {/* Modal with progress */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Processing File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar now={progress} label={`${progress}%`} />
          <p>{progressText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
