import { DiagramEngine, DiagramModel, DefaultLinkModel } from "@projectstorm/react-diagrams";

import { CustomNodeModel } from './customnode/node/CustomNodeModel.ts'
import { getEntrypointsArray } from './../../../helpers/entrypoints.js';
import { getExitpointsArray } from './../../../helpers/exitpoints.js';
import { CustomPortModel } from './customnode/port/CustomPortModel.ts';

export const nodeColors = {
    'default': '#ff0000',
    'entrypoint': '#588ffe',
    'exitpoint': '#49a3fd',
    'profile': '#a37c2d',
    'transformer': '#969650',
    'mapping': '#6e2e87',
    'function': '#530b52',
    'expression': '#c4c3c3',
    'tunnel': '#58b358',
    'filetunnel': '#16c319ff',
    'deliverypoint': '#356c47'
}

export class TunnelModel extends DiagramModel {
    protected tunnelNodes: Map<string, object>;
    protected exitpointNodes: Map<string, object>;
    protected entrypointNodes: Map<string, object>;
    protected profileNodes: Map<string, object>;
    protected transformerNodes: Map<string, object>;
    protected mappingNodes: Map<string, object>;
    protected functionNodes: Map<string, object>;
    protected fileTunnelNodes: Map<string, object>;

    constructor(gridSize, config) {
        super({ gridSize: gridSize });
        this.tunnelNodes = new Map();
        this.exitpointNodes = new Map();
        this.entrypointNodes = new Map();
        this.profileNodes = new Map();
        this.transformerNodes = new Map();
        this.mappingNodes = new Map();
        this.functionNodes = new Map();
        this.fileTunnelNodes = new Map();

        this.registerListener({
            eventDidFire: event => {
                const type = event.function;
                if (type === 'offsetUpdated') this.adjustGridOffset(event);
                if (type === 'zoomUpdated') this.adjustGridZoom(event);
            },
        });

        if (config)
            this.loadConfig(config);
    }

    adjustGridOffset = ({ offsetX, offsetY }) => {
        document.body.style.setProperty(
            '--offset-x',
            `${Math.round(offsetX)}px`,
        );
        document.body.style.setProperty(
            '--offset-y',
            `${Math.round(offsetY)}px`,
        );
    };

    adjustGridZoom = ({ zoom }) => {
        var { gridSize } = this.getOptions();
        if (!gridSize) gridSize = 50;
        // console.log(zoom)
        document.body.style.setProperty(
            '--grid-size',
            `${(gridSize * zoom) / 100}px`,
        );
    };

    realignGrid = () => {
        this.adjustGridOffset({
            offsetX: this.getOffsetX(),
            offsetY: this.getOffsetY(),
        });

        this.adjustGridZoom({
            zoom: this.getZoomLevel(),
        });
    };

    loadConfig(config: any) {

        //ENTRYPOINTS
        getEntrypointsArray(config).map((ep, index) => {
            return this.getMapedNode(this.entrypointNodes, ep);
        })

        //EXITPOINTS
        getExitpointsArray(config).map((exp, index): CustomNodeModel => {
            const exitpointNode = this.getMapedNode(this.exitpointNodes, exp);
            //create ports for INVM connections
            if (exp.protocol !== 'INVM') {
                exitpointNode.addOutPort('protocol: ' + exp.protocol)
            }
            //link the exitpoint to the INVM entrypoint
            if (exp.invmEntryPoint) {
                const entrypointNode = this.getMapedNode(this.entrypointNodes, exp.invmEntryPoint);
                const inPort = entrypointNode.addInPort('INVM ' + exp.name)
                const outPort = exitpointNode.addOutPort('INVM ' + exp.invmEntryPoint.name)
                const link = this.addNodeLink(outPort, inPort);
                link.setColor('#0000ff');
            }
            if (exp.filetunnel) {
                // const fgileTunnelNode = this.getNodeFromCode(this.fil)
                // console.log('found filetunnel: ')
                // console.log(exp.filetunnel)
                const fileTunnelNode = this.getMapedNode(this.fileTunnelNodes, exp.filetunnel);
                const inPort = fileTunnelNode.addInPort('selector ' + exp.name);
                const outPort = exitpointNode.addOutPort('filetunnel ' + exp.filetunnel.name);
                const link = this.addNodeLink(outPort, inPort);
            }
            return exitpointNode;
        })

        //MAPPINGS
        for (const code in config.mappings.transformationMappings) {
            const mapping = config.mappings.transformationMappings[code];
            this.getMapedNode(this.mappingNodes, mapping);
        }

        //FUNCTIONS
        for (const code in config.functionsMap) {
            const funct = config.functionsMap[code];
            const functionNode = this.getMapedNode(this.functionNodes, funct);
            this.attachDependencies(functionNode, funct.name, funct.dependenciesList)
        }

        //TRANSFORMERS
        for (const code in config.messageTransformerMap) {
            const transformer = config.messageTransformerMap[code]
            const transformerNode = this.getMapedNode(this.transformerNodes, transformer);
            //dependencies
            this.attachDependencies(transformerNode, transformer.name, transformer.dependenciesList)

            //iterate transformer variables for exitpointcode
            this.attachVariables(transformerNode, transformer.name, transformer.variables);

        }

        //PROFILES
        for (const code in config.outgoingProfilesMap) {
            const profile = config.outgoingProfilesMap[code];
            const profileNode = this.getMapedNode(this.profileNodes, profile);

            profile.transformers.map((transformer, index) => {
                const transformerNode = this.transformerNodes[transformer.template.code];
                if (transformer.conditional) {
                    const expressionNode = this.addExpression(transformer.conditional)
                    const expressionInPort = expressionNode.addInPort('In ' + index);
                    const expressionOutPort = expressionNode.addOutPort('Out ' + index);
                    const profileOutPort = profileNode.addOutPort('Expression:' + index);
                    const transformerInPort = transformerNode.addInPort(profile.name + index);
                    this.addNodeLink(profileOutPort, expressionInPort);
                    this.addNodeLink(expressionOutPort, transformerInPort)
                } else {
                    const inPort = transformerNode.addInPort('Profile ' + profile.name);
                    const outPort = profileNode.addOutPort('Transformer ' + transformer.template.name);
                    this.addNodeLink(outPort, inPort);
                }
                // //dependencies
                // this.attachDependencies(this.model, transformerNode, transformer.template.name, transformer.template.dependenciesList)

                // //iterate transformer variables for exitpointcode
                // this.attachVariables(this.model, transformerNode, transformer.template.name, transformer.template.variables);
                return transformerNode;
            });
        }

        //FILE_TUNNELS
        config.filetunnels.map((fileTunnel, tunnelIndex) => {
            const tunnelName = 'FileTunnel ' + fileTunnel.name
            const fileTunnelNode = this.getMapedNode(this.fileTunnelNodes, fileTunnel);
            console.log(fileTunnelNode);

            if (fileTunnel.fetch_exitpoint) {
                const fetchExitpointNode = this.getMapedNode(this.exitpointNodes, fileTunnel.fetch_exitpoint);
                const fileTunnelFetchExitpontPort = fileTunnelNode.addOutPort('fetchExitPoint ' + fileTunnel.selector)
                const fetchExitpointNodeOutPort = fetchExitpointNode.addInPort('fileTunnel fetch ' + fileTunnel.selector)
                this.addNodeLink(fileTunnelFetchExitpontPort, fetchExitpointNodeOutPort)
            }

            if (fileTunnel.upload_exitpoint) {
                const uploadExitpointNode = this.getMapedNode(this.exitpointNodes, fileTunnel.upload_exitpoint);
                const fileTunnelUploadExitpontPort = fileTunnelNode.addOutPort('uploadExitPoint ' + fileTunnel.selector)
                const uploadExitpointNodeOutPort = uploadExitpointNode.addInPort('fileTunnel upload ' + fileTunnel.selector)
                this.addNodeLink(fileTunnelUploadExitpontPort, uploadExitpointNodeOutPort)
            }

            if (fileTunnel.notificators.length > 0) {
                fileTunnel.notificators.map((notificatorExitpoint, index) => {
                    const notifyExitpointNode = this.getMapedNode(this.exitpointNodes, notificatorExitpoint);
                    const fileTunnelNotifyExitpointPort = fileTunnelNode.addOutPort('notifyExitPoint ' + index + ' ' + notificatorExitpoint.name)
                    const notifyExitpointNodeOutPort = notifyExitpointNode.addInPort('fileTunnel notify '+ index + ' ' + fileTunnel.selector)
                    this.addNodeLink(fileTunnelNotifyExitpointPort, notifyExitpointNodeOutPort)
                });
            }
        })

        //TUNNELS
        //Now parse alltunnels and stich it all together
        config.allTunnels.map((tunnel, tunnelIndex) => {
            const tunnelName = 'Tunnel#' + tunnelIndex + ' ' + tunnel.name
            const tunnelNode = this.getMapedNode(this.tunnelNodes, tunnel);

            //ENTRYPOINTS
            tunnel.entrypoints.map((entrypoint, entrypointIndex) => {
                const entrypointNode = this.getMapedNode(this.entrypointNodes, entrypoint);
                const entrypointOutPort = entrypointNode.addOutPort('Tunnel#' + tunnelIndex);
                const tunnelInPort = tunnelNode.addInPort(entrypoint.name)
                this.addNodeLink(entrypointOutPort, tunnelInPort)
                return entrypointNode;
            });

            //ASYNC_EXITPOINT connect tunelAsyncexitpoint to exitpoint
            if (tunnel.asyncExitpoint) {
                const asyncExitpointNode = this.getMapedNode(this.exitpointNodes, tunnel.asyncExitpoint);
                const tunnelAsyncExitpointOutPort = tunnelNode.addOutPort('asyncExitpoint: ' + tunnel.asyncExitpoint.name);
                tunnelAsyncExitpointOutPort.getOptions().backgroundColor = '#fcd703'
                const asyncExitpointInPort = asyncExitpointNode.addInPort('tunnel#' + tunnelIndex + ' ' + tunnel.name);
                const link = this.addNodeLink(tunnelAsyncExitpointOutPort, asyncExitpointInPort);
                link.setColor('#ff0000');
            }

            if (tunnel.runtimeVariables) {
                this.attachVariables(tunnelNode, 'variable: ' + tunnelName, tunnel.runtimeVariables)
            }

            //DELIVERYPOINTS
            tunnel.deliverypoints.map((deliverypoint, index) => {
                const deliverypointName = ' Deliverypoint#' + index + ' ' + tunnelName;
                deliverypoint.name = deliverypointName;
                const deliverypointNode = this.addEntityNode(deliverypoint);

                var exitpointNode = this.getMapedNode(this.exitpointNodes, deliverypoint.exitpoint);
                const tunnelOutPort = tunnelNode.addOutPort('Deliverypoint#' + index);
                const deliverypointInPort = deliverypointNode.addInPort('Tunnel#' + tunnelIndex + ' ' + deliverypointName);

                //check for expression on deliverypoint
                if (deliverypoint.conditional) {
                    //add expression
                    const expressionNode = this.addExpression(deliverypoint.conditional)
                    const expressionInPort = expressionNode.addInPort('Tunnel#' + tunnelIndex + ' ' + deliverypointName);
                    const expressionOutPort = expressionNode.addOutPort('Deliverypoint#' + index);
                    this.addNodeLink(tunnelOutPort, expressionInPort);
                    this.addNodeLink(expressionOutPort, deliverypointInPort);
                } else {
                    // just connect tunnel to deliverypoint
                    this.addNodeLink(tunnelOutPort, deliverypointInPort);
                }

                //DELIVERYPOINT PROFILE
                if (deliverypoint.profile) {
                    const profileNode = this.getMapedNode(this.profileNodes, deliverypoint.profile)
                    const profileInPort = profileNode.addInPort('Tunnel#' + tunnelIndex + ' ' + deliverypointName)
                    const deliverypointProfileOutPort = deliverypointNode.addOutPort('Profile: ' + deliverypoint.profile.name);
                    this.addNodeLink(deliverypointProfileOutPort, profileInPort);
                }

                //DELIVERYPOINT EXITPOINT
                const exitpointInPort = exitpointNode.addInPort('Tunnel#' + tunnelIndex + ' ' + deliverypointName);
                const deliverypointExitpointOutPort = deliverypointNode.addOutPort('Exitpoint: ' + deliverypoint.exitpoint.name);
                this.addNodeLink(deliverypointExitpointOutPort, exitpointInPort);
                return deliverypointNode;
            })

            //RESPONSE PROFILE
            if (tunnel.responseProfile) {
                const responseProfileNode = this.getMapedNode(this.profileNodes, tunnel.responseProfile)
                const profileInPort = responseProfileNode.addInPort('Tunnel#' + tunnelIndex + " response-profile")
                const tunnelResponseProfileOutPort = tunnelNode.addOutPort('Response-Profile: ' + tunnel.responseProfile.name);
                tunnelResponseProfileOutPort.getOptions().backgroundColor = '#ebb134'
                const link = this.addNodeLink(tunnelResponseProfileOutPort, profileInPort);
                link.setColor('#10cc10');
            }
            return tunnelNode
        })

        this.realignGrid();
    }

    //GENERIC FUNCTIONS should be private

    //Attach all dependencies to the specific nodes
    attachDependencies(sourceNode: CustomNodeModel, sourceName: string, dependencyList: any) {
        if (dependencyList) {
            dependencyList.map((dependency, index) => {
                // console.log(dependency)
                switch (dependency.type) {
                    case 'exitpoint':
                        const exitpointNode = this.getMapedNode(this.exitpointNodes, dependency.exitpoint);
                        const sourceExitpointOut = sourceNode.addOutPort('dependency_exitpoint' + index + ' ' + dependency.exitpoint.name);
                        const exitpointIn = exitpointNode.addInPort('transformer dependency' + index + ' ' + sourceName);
                        const exitpointLink = this.addNodeLink(sourceExitpointOut, exitpointIn);
                        exitpointLink.setColor('#0000ff');
                        break;
                    case 'profile':
                        const profileNode = this.getMapedNode(this.profileNodes, dependency.profile);
                        const SourceProfileOut = sourceNode.addOutPort('dependency_profile' + index + ' ' + dependency.profile.name);
                        const profileIn = profileNode.addInPort('transformer dependency' + index + ' ' + sourceName);
                        const profileLink = this.addNodeLink(SourceProfileOut, profileIn);
                        profileLink.setColor('#0000ff');
                        break;
                    case 'mapping':
                        const mappingNode = this.getMapedNode(this.mappingNodes, dependency.mapping);
                        const SourceMappingOut = sourceNode.addOutPort('dependency_mapping' + index + ' ' + dependency.mapping.name);
                        const mappingNodeIn = mappingNode.addInPort('transformer dependency' + index + ' ' + sourceName);
                        const mappingLink = this.addNodeLink(SourceMappingOut, mappingNodeIn);
                        mappingLink.setColor('#0000ff');
                        break;
                    case 'function':
                        // console.log(dependency.function)
                        const functionNode = this.getMapedNode(this.functionNodes, dependency.function);
                        const SourceFunctionOut = sourceNode.addOutPort('dependency_mapping' + index + ' ' + dependency.function.name);
                        const functionNodeIn = functionNode.addInPort('transformer dependency' + index + ' ' + sourceName);
                        const functionLink = this.addNodeLink(SourceFunctionOut, functionNodeIn);
                        functionLink.setColor('#0000ff');
                        break;
                    default:
                        break;
                }
                return dependency.type;
            })
        }
    }

    //attach all variables to specific nodes
    attachVariables(sourceNode: CustomNodeModel, sourceName: string, variableList): string {
        variableList.map((variable, index) => {
            if (variable.exitpoint) {
                const exitpointNode = this.getNodeFromCode(this.exitpointNodes, variable.exitpoint.code);
                const sourceNodeOut = sourceNode.addOutPort('variable' + index + ' ' + variable.name + ' exitpoint');
                const exitpointIn = exitpointNode.addInPort('variable ' + variable.name + ' ' + sourceName);
                this.addNodeLink(sourceNodeOut, exitpointIn);
            } else if (variable.mapping) {
                const mappingNode = this.getNodeFromCode(this.mappingNodes, variable.mapping.code);
                const sourceNodeOut = sourceNode.addOutPort('variable' + index + ' ' + variable.name + ' mapping');
                const mappingIn = mappingNode.addInPort('variable ' + variable.name + ' ' + sourceName);
                this.addNodeLink(sourceNodeOut, mappingIn);
            } else if (variable.function) {
                const functionNode = this.getNodeFromCode(this.functionNodes, variable.function.code);
                // console.log(functionNode)
                const sourceNodeOut = sourceNode.addOutPort('variable' + index + ' ' + variable.name + ' function');
                const functionIn = functionNode.addInPort('variable ' + variable.name + ' ' + sourceName);
                this.addNodeLink(sourceNodeOut, functionIn);
            }
            return variable.value;
        })
        return '';
    }

    getNodeColor(nodeType: string): string {
        if (nodeColors[nodeType]) {
            return nodeColors[nodeType];
        }
        return nodeColors['default'];
    }

    addEntityNode(entity: any): CustomNodeModel {
        const entityNode = new CustomNodeModel({
            name: entity.type + ': ' + entity.name,
            color: this.getNodeColor(entity.type),
            nodetype: entity.type
        });
        // entityNode.setPosition(0, 0);
        entityNode.setUserData(entity)

        this.addNode(entityNode);
        return entityNode;
    }

    getNodeFromCode(nodeMap: Map<string, object>, code: string): CustomNodeModel {
        if (nodeMap[code]) {
            return nodeMap[code];
        }
        const entity = { code: code, name: 'undefined', type: 'default' }
        const entityNode = this.addEntityNode(entity);
        nodeMap[entity.code] = entityNode;
        return entityNode;
    }

    getMapedNode(nodeMap: Map<string, object>, entity: any): CustomNodeModel {
        if (nodeMap[entity.code]) {
            return nodeMap[entity.code];
        }
        const entityNode = this.addEntityNode(entity);
        nodeMap[entity.code] = entityNode;
        return entityNode;
    }

    addExpression(expression: string): CustomNodeModel {
        var node_expression = new CustomNodeModel({
            name: 'Expression: ' + expression,
            color: '#b3b3b3',
            nodetype: 'expression'
        });
        node_expression.setUserData(expression)
        this.addNode(node_expression);
        return node_expression;
    }

    addNodeLink(source: CustomPortModel, target: CustomPortModel): DefaultLinkModel {
        // let link = new DefaultLinkModel();
        // link.setSourcePort(source);
        // link.setTargetPort(target);
        const link: DefaultLinkModel = source.link(target);
        this.addLink(link);
        // link.addLabel('does not work');
        return link;
    }
}