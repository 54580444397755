import isIterable from "./helper";

function setExitpoints(filetunnel, config) {
    // notification_exitpoints
    // upload_exitpoint
    // fetch_exitpoint
    const fetchCode = filetunnel['fetch-end-exitpoint-ref'];
    const uploadCode = filetunnel['upload-end-exitpoint-ref'];

    filetunnel.fetch_exitpoint = config.exitpointmap[fetchCode]
    filetunnel.upload_exitpoint = config.exitpointmap[uploadCode]
    filetunnel.notificators = [];

    // const notificators = filetunnel['file-tunnel-notificators'];
    if (filetunnel['file-tunnel-notificators']['file-tunnel-notificator']) {
        if (isIterable(filetunnel['file-tunnel-notificators']['file-tunnel-notificator'])) {
            for (const notificator of filetunnel['file-tunnel-notificators']['file-tunnel-notificator']) {
                const ref = notificator['exitpoint-ref']
                filetunnel.notificators.push(config.exitpointmap[ref]);
            }
        } else {
            const ref = filetunnel['file-tunnel-notificators']['file-tunnel-notificator']['exitpoint-ref']
            filetunnel.notificators.push(config.exitpointmap[ref]);
        }
    }
}

function addFileSelectorCode(filetunnel, param) {
    if (param['prop-name'] === 'selector.filetunnel') {
        filetunnel.selector = param['prop-value'];
    }
}

function fixParams(filetunnel) {
    const variables = [];
    const params = filetunnel['file-tunnel-params'];
    var order = 0;
    if (params['file-tunnel-param']) {
        if (isIterable(params['file-tunnel-param'])) {
            for (const param of params['file-tunnel-param']) {
                variables.push({ "name": param['prop-name'], "value": param['prop-value'], "order": order++ })
                addFileSelectorCode(filetunnel, param);
            }
        } else {
            variables.push({ "name": params['file-tunnel-param']['prop-name'], "value": params['file-tunnel-param']['prop-value'], "order": order++ })
            addFileSelectorCode(filetunnel, params['file-tunnel-param']);
        }
    }
    filetunnel.variables = variables;

}

function getFilePipelinePlugins(list, plugins, config) {
    // console.log(plugins)
    if (plugins['file-tunnels']) {
        if (isIterable(plugins['file-tunnels']['file-tunnel'])) {
            for (const filetunnel of plugins['file-tunnels']['file-tunnel']) {
                filetunnel.code = filetunnel._attributes.code;
                filetunnel.type = "filetunnel";
                list.push(filetunnel);
                fixParams(filetunnel);
                setExitpoints(filetunnel, config);
            }
        } else {
            const filetunnel = plugins['file-tunnels']['file-tunnel'];
            filetunnel.code = filetunnel._attributes.code;
            filetunnel.type = "filetunnel";
            list.push(filetunnel);
            fixParams(filetunnel);
            setExitpoints(filetunnel , config);
        }
    }
}

/**
 * 
 * @param {*} configuration 
 */
function buildPlugins(configuration) {
    const list = [];
    if (configuration.plugins) {
        const plugins = configuration.plugins;
        getFilePipelinePlugins(list, plugins['filepipeline-plugin'], configuration)
    }

    return list
}

function getFiletunnelBySelector(filetunnels, selector) {
    for (const ft of filetunnels) {
        // console.log(ft)
        // console.log('CHECK: ' + ft.selector + ' match ' + selector)
        if (ft.selector === selector) {
            // console.log('add ft for ' + ft.selector)
            // console.log(ft)
            return ft;
        }

    }
    return undefined;
}

function updateExitpointsForFiletunnels(configuration) {
    // console.log('updateExitpointsForFiletunnels')
    for (const ep_code in configuration.exitpointmap) {
        const ep = configuration.exitpointmap[ep_code];
        if (ep.selectorFiletunnel) {
            // console.log(ep)
            // console.log('found selector ' + ep.selectorFiletunnel)
            ep.filetunnel = getFiletunnelBySelector(configuration.filetunnels, ep.selectorFiletunnel);
            // console.log(ep)
        }
    }
    // console.log('DONE')
}

export { buildPlugins, updateExitpointsForFiletunnels }