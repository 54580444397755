import React, { useState } from "react";
import { Badge, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import BadgeExtended from "../react/BadgeExtended";
import eventBus from "../../EventBus";

function Mapping({ data }) {
    const [itemVisible, setItemVisiblity] = useState(false);
    return (
        <div className='mb-1 nowrap' key={data.code + 'cont'} >
            <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
                onClick={(e) => {
                    setItemVisiblity((v) => !v);
                }} />
            <Badge bg="secondary">Mapping</Badge>
            <Badge className="ms-2 badge-80" bg={`${data.valueList.length === 0 ? "danger" : "primary"}`}>{data.valueList.length} Values</Badge>
            <BadgeExtended className="ms-2 e-badge-name text-dark" bg="" tooltip="click to copy" copytext={data.name}>{data.name}</BadgeExtended>
            <Badge
                className="ms-2"
                bg="info"
                onClick={(e) => {
                    handleExportClick(data);
                }}>Export</Badge>
            <div className="ms-4" hidden={!itemVisible}>
                <Values values={data.valueList} data={data}/>
            </div>
        </div>
    )
}

function Values({ data, values }) {
    const [searchText, setSearchText] = useState([])
    return (
        <div className=" ">
            <table className="mt-2 variable-table" style={{ fontSize: "14px" }}>
                <thead>
                    <tr><th colSpan={2}>
                        <FormControl
                            id="search-mapping"
                            type="text"
                            placeholder="Search"
                            hidden={values.length < 5}
                            onChange={e => setSearchText(e.target.value.toLowerCase())}
                        />
                    </th></tr>
                    <tr hidden={!data['default-value']} bgcolor="#bcfebc"><th>Default value</th><th>{data['default-value']}</th></tr>
                    <tr key="0a">
                        <th>source</th>
                        <th>target</th>
                    </tr>
                </thead>
                <tbody>
                    {values.filter(value => (value.key.toLowerCase().indexOf(searchText) !== -1) || (value.value.toLowerCase().indexOf(searchText) !== -1)).map((value) => (
                        <tr key={value.key}><td>{value.key}</td><td>{value.value}</td></tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

function handleExportClick(data) {
    const filename = data.name.replace(/\s+|#/g, '_');
    const result = ['"Source value","Target value"']
    // console.log(data)

    data.values.value.map((val) => {
        if (val["source-keys"])
            result.push('"' + val["source-keys"]["source-key"].key + '", "' + val["target-key"] + '"')
        else
            result.push('"' + val["source-key"] + '", "' + val["target-key"] + '"')
    })
    var blob = new Blob([result.join("\n")], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, filename + ".csv");
}

export default Mapping
