function addDeliveryPoint(deliverypoint, tunnel, configuration) {
    // console.log(deliverypoint)
    deliverypoint.code = deliverypoint._attributes.code
    deliverypoint.profile = undefined
    const exitpoint = configuration.exitpointmap[deliverypoint['exitpoint-ref']];
    deliverypoint.exitpoint = exitpoint;
    if (deliverypoint.expression !== undefined && Object.prototype.toString.call(deliverypoint.expression) === "[object String]") {
        deliverypoint.conditional = deliverypoint.expression + ' == ' + deliverypoint['expr-value']
    }
    deliverypoint.number = deliverypoint['seq-number']
    deliverypoint.type = "deliverypoint"

    if (deliverypoint['outgoing-profile-ref'] !== undefined) {
        const profile = configuration.outgoingProfilesMap[deliverypoint['outgoing-profile-ref']]
        deliverypoint.profile = profile
    }
    return deliverypoint
}

export { addDeliveryPoint }