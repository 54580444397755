import React, { useState } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Entrypoints from "./Entrypoints";
import Variables from "./Variables";
import Deliverypoints from "./Deliverypoints";
import Profile from "./Profile";
import AsyncExitpoint from "./AsyncExitpoint";
import Exitpoint from "./Exitpoint";
import Exitpoints from "./ExitPoints";

export default function FileTunnel({ data }) {
  const [itemVisible, setItemVisiblity] = useState(false);
  return (
    <div className='mb-1 nowrap' >
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge bg="secondary"
      >FileTunnel</Badge>
      <Badge className="ms-2">{data.notificators.length} Notificators(s)</Badge>
      <Badge className="ms-2" bg='warning' hidden={data['delete-temp-files'] !== 'true'}>delete-temp-files</Badge>
      <Badge className="ms-2" bg='warning' hidden={data['transport-parameters-or-join'] !== 'true'}>transport-parameters-or-join</Badge>
      {/* <Badge className="ms-2">{getDelioveryPoints(data.deliverypoints).length} Deliverypoint(s)</Badge> */}
      {/* {getVariables(data.runtimeVariables)} */}
      {/* <Badge className="ms-2" bg="warning" hidden={data.responseProfile === undefined}>responseProfile</Badge> */}
      <div className="e-badge-name text-dark size_3 ms-2">{data.name}</div>
      <Collapse in={itemVisible}>
        <div>  {itemVisible && (
          <div className='row  ms-4'>
            <TunnelContent data={data} />
          </div>
        )}
        </div>
      </Collapse>
    </div>
  )
}

function getVariables(variables) {
  if (variables !== undefined && variables.length > 0) {
    return <Badge className="ms-2">{variables.length} Variables</Badge>
  }
}

function getDelioveryPoints(dp) {
  if (dp === undefined)
    return []
  return dp
}

function getAsyncExitpoint(exitpoint) {
  if (exitpoint !== undefined)
    return <AsyncExitpoint data={exitpoint} />
}

function TunnelContent({ data }) {
  return (
    <div >
      <Exitpoint data={data.fetch_exitpoint} type={'Fetch Exitpoint'} />
      <Exitpoint data={data.upload_exitpoint} type={'Upload Exitpoint'} />
      <Exitpoints data={data.notificators} type={'Notificators'} />
      <Variables data={data.variables} name={data.name} type={'Parameters'} />
    </div>
  )
}
