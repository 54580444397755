import React, { useState, useEffect } from "react";
import { Badge, Collapse, ProgressBar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Tunnel from "./Tunnel";
import './Tunnels.css';

import eventBus from '../../EventBus.js';
var needsToRegister = true;
var activeTab = 'home';

library.add(faCaretRight);

export default function Tunnels({ data }) {
  const [itemVisible, setItemVisiblity] = useState(false);
  const [visibleTunnels, setVisibleTunnels] = useState([]);
  const [progress, setProgress] = useState(0);
  const [currentTunnelName, setCurrentTunnelName] = useState("");
  const [isRendering, setIsRendering] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (itemVisible && activeTab === 'home') {
      setShowModal(true);
      setIsRendering(true); // Start rendering flag
      setVisibleTunnels([]); // Clear previous tunnels

      // Simulate batch rendering to avoid UI freezing
      const renderTimeout = setTimeout(() => {
        setVisibleTunnels(data); // Render all tunnels at once
        setIsRendering(false); // Rendering done
      }, 100); // Short delay to give modal time to show first

      return () => clearTimeout(renderTimeout);
    } else {
      setVisibleTunnels([]);
      setShowModal(false);
    }
  }, [itemVisible, data]);

  // Watch `isRendering` to close the modal when rendering is complete
  useEffect(() => {
    if (!isRendering && visibleTunnels.length === data.length) {
      setShowModal(false);
    }
  }, [isRendering, visibleTunnels, data.length]);

  useEffect(() => {
    // console.log('useEffect');
    if (needsToRegister) {
      needsToRegister = false;
      eventBus.on('active-tab', (activeTabName) => {
        console.log(activeTabName);
        activeTab = activeTabName;
      });
    }
  })

  return (
    <div className='mb-1'>
      <FontAwesomeIcon
        icon={faCaretRight}
        className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={() => setItemVisiblity((v) => !v)}
      />
      <Badge className={`badge-fixed ${showModal ? 'pulsing-badge' : 'badge-fixed'}`} bg="secondary">{data.length} Tunnels</Badge>

      <Collapse in={itemVisible}>
        <div className='row ms-4'>
          {visibleTunnels.map((tunnel) => (
            <Tunnel key={tunnel.code} data={tunnel} />
          ))}
        </div>
      </Collapse>

      {/* Modal for Progress */}
      <Modal show={showModal} centered scrollable>
        <Modal.Header >
          <Modal.Title>{`Render ${data.length} Tunnels`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ProgressBar now={progress} label={`${progress}%`} /> */}
          {/* {currentTunnelName && <div className="mt-2">{currentTunnelName}</div>} */}
        </Modal.Body>
      </Modal>
    </div>
  );
}
