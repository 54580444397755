import * as React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { CustomNodeModel } from './CustomNodeModel.ts';
import { CustomPortLabel } from '../port/CustomPortLabelWidget.tsx';
import eventBus from '../../../../../EventBus.js';

export interface BaseNodeProps {
    node: CustomNodeModel;
    engine: DiagramEngine;
}

export class BaseNodeComponent extends React.Component<BaseNodeProps> {
    node: any;
    engine: DiagramEngine;
    dataNode: any;
    needsToRegister: boolean = true;
    constructor(props: BaseNodeProps) {
        super(props);
        this.node = this.props.node;
        this.engine = this.props.engine;
        this.dataNode = this.node.getUserData();
        //snap to grid
        // this.node.registerListener({
        //     positionChanged: this.gridPosition
        // });

        eventBus.on('node-show', (data: any) => {
            if (data.code === this.dataNode.code) {
                // console.log(data)
                this.setSelected(true);
                eventBus.dispatch("show-flow", this.dataNode);
                eventBus.dispatch("zoom-selected", this.dataNode);
            }
        });
    }

    

    gridPosition(e) {
        // console.log(e)
        const model = e.entity.parent.parent
        // console.log(model)
        const entityPos = e.entity.position;
        const gridCubeWidth = 50;
        const gridCubeHeight = 50;

        entityPos.x = Math.round(entityPos.x / gridCubeWidth) * gridCubeWidth;
        entityPos.y = Math.round(entityPos.y / gridCubeHeight) * gridCubeHeight;
    }

    generatePort = (port) => {
        return <CustomPortLabel engine={this.props.engine} port={port} key={port.getID()} />;
    };

    getNodeType(): string {
        return this.node.getOptions().nodetype;
    }

    setSelected(selected: boolean) {
        this.node.setSelected(selected);
    }

    toggleDetails(node: any) {
        node.toggleDetailsHide();
        this.forceUpdate();
    }

    getDescription() {
        if (this.dataNode.description)
            return this.dataNode.description.toString();
        return '';
    }

    getRuntimeVariables(): Array<any> {
        const userData = this.props.node.getUserData();
        // console.log(userData)
        if (userData.runtimeVariables)
            return userData.runtimeVariables;
        return [];
    }

    hasRuntimeVariables(): boolean {
        const runtimevars = this.props.node.getUserData().runtimeVariables;
        if (runtimevars)
            return runtimevars.length !== 0;
        return false;
    }

    getVariables(): Array<any> {
        const userData = this.props.node.getUserData();
        // console.log(userData)
        if (userData.variables)
            return userData.variables;
        return [];
    }

    hasVariables(): boolean {
        const runtimevars = this.props.node.getUserData().variables;
        return runtimevars.length !== 0;
    }

    getValues(): Array<any> {
        const userData = this.props.node.getUserData();
        if (userData.valueList)
            return userData.valueList;
        return [];
    }

    hasValues(): boolean {
        if (this.props.node.getUserData().valueList)
            return this.props.node.getUserData().valueList.length !== 0;
        return false;
    }

    getConfigParameters(): Array<any> {
        const userData = this.props.node.getUserData();
        if (userData.config_parameters)
            return userData.config_parameters;
        return [];
    }

    hasConfigParameters(): boolean {
        if (this.props.node.getUserData().config_parameters)
            return this.props.node.getUserData().config_parameters.length !== 0;
        return false;
    }

    getTransportParameters(): Array<any> {
        const userData = this.props.node.getUserData();
        // console.log(userData)
        if (userData.transport_parameters)
            return userData.transport_parameters;
        return [];
    }

    hasTransportParameters(): boolean {
        const parameters = this.props.node.getUserData().transport_parameters;
        if (parameters)
            return parameters.length !== 0;
        return false;
    }

    getArrayData(configArray): Array<any> {
        if (configArray)
            return configArray;
        return [];
    }

    hasArrayData(configArray): boolean {
        if (configArray)
            return configArray.length !== 0;
        return false;
    }

    zoomSelectedNodes() {
        this.engine.zoomToFitSelectedNodes({ margin: 10, maxZoom: 100 });
    }

    render() {
        return (
            <div>Please extend the BaseNodeComponent</div>
        )
    }

}