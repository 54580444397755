import _ from 'lodash';
import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams-core';
import { CustomPortModel } from '../port/CustomPortModel.ts';
import { BasePositionModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';

export interface CustomNodeModelOptions extends BasePositionModelOptions {
	name?: string;
	color?: string;
	nodetype?: string;
	hideDetails?: boolean;
}

export interface DefaultNodeModelGenerics extends NodeModelGenerics {
	OPTIONS: CustomNodeModelOptions;
}

export class CustomNodeModel extends NodeModel<DefaultNodeModelGenerics> {
	protected portsIn: CustomPortModel[];
	protected portsOut: CustomPortModel[];
	protected userData: any = {};

	constructor(name: string, color: string, nodetype: string);
	constructor(options?: CustomNodeModelOptions);
	constructor(options: any = {}, color?: string, nodetype?: string) {
		if (typeof options === 'string') {
			options = {
				name: options,
				color: color,
				nodetype: nodetype,
				hideDetails: true,
			};
		}
		super({
			type: 'custom',
			name: 'Untitled',
			color: 'rgb(0,192,255)',
			nodetype: 'unknown',
			hideDetails: true,
			...options
		});
		this.portsOut = [];
		this.portsIn = [];
	}

	doClone(lookupTable: {}, clone: any): void {
		clone.portsIn = [];
		clone.portsOut = [];
		super.doClone(lookupTable, clone);
	}

	removePort(port: CustomPortModel): void {
		super.removePort(port);
		if (port.getOptions().in) {
			this.portsIn.splice(this.portsIn.indexOf(port), 1);
		} else {
			this.portsOut.splice(this.portsOut.indexOf(port), 1);
		}
	}

	addPort<T extends CustomPortModel>(port: T): T {
		super.addPort(port);
		if (port.getOptions().in) {
			if (this.portsIn.indexOf(port) === -1) {
				this.portsIn.push(port);
			}
		} else {
			if (this.portsOut.indexOf(port) === -1) {
				this.portsOut.push(port);
			}
		}
		return port;
	}

	addInPort(label: string, after = true): CustomPortModel {
		const p = new CustomPortModel({
			in: true,
			name: label,
			label: label,
			alignment: PortModelAlignment.LEFT
		});
		if (!after) {
			this.portsIn.splice(0, 0, p);
		}
		return this.addPort(p);
	}

	addOutPort(label: string, after = true): CustomPortModel {
		const p = new CustomPortModel({
			in: false,
			name: label,
			label: label,
			alignment: PortModelAlignment.RIGHT
		});
		if (!after) {
			this.portsOut.splice(0, 0, p);
		}
		return this.addPort(p);
	}

	deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
		this.options.name = event.data.name;
		this.options.color = event.data.color;
		this.portsIn = _.map(event.data.portsInOrder, (id) => {
			return this.getPortFromID(id);
		}) as CustomPortModel[];
		this.portsOut = _.map(event.data.portsOutOrder, (id) => {
			return this.getPortFromID(id);
		}) as CustomPortModel[];
	}

	serialize(): any {
		return {
			...super.serialize(),
			name: this.options.name,
			color: this.options.color,
			portsInOrder: _.map(this.portsIn, (port) => {
				return port.getID();
			}),
			portsOutOrder: _.map(this.portsOut, (port) => {
				return port.getID();
			})
		};
	}

	getInPorts(): CustomPortModel[] {
		return this.portsIn;
	}

	getOutPorts(): CustomPortModel[] {
		return this.portsOut;
	}

	getName(): string {
		if (this.options.name)
			return this.options.name;
		return 'no_name'
	}

	getColor(): string {
		if (this.options.color)
			return this.options.color
		return 'rgb(0,192,255)';
	}

	setColor(color: string) {
		this.options.color = color;
	}

	setUserData(data: any) {
		this.userData = data;
	}

	getUserData(): any {
		return this.userData;
	}

	getDescription(): string {
		if(this.userData) {
			// console.log(this.userData.description)
			if(this.userData.description && typeof this.userData.description === 'string'){
				return this.userData.description.toString();
			}
		}
		return 'no description';
	}

	needsToHide(): boolean {
		if (this.options.hideDetails !== undefined)
			return this.options.hideDetails;
		return true;
	}

	setHideDetails(hid: boolean) {
		this.options.hideDetails = hid;
	}

	toggleDetailsHide(): boolean {
		this.options.hideDetails = !this.options.hideDetails;
		return this.options.hideDetails;
	}
}
