import isIterable from "./helper";

function addFullUrl(exitpoint) {
    let fullUrl = exitpoint.protocol.toLowerCase();
    const regex = /esb|invm/;
    if (!regex.test(exitpoint.protocol.toLowerCase())) {
        exitpoint.host??='';
        exitpoint.contextPath??='';
        fullUrl += "://" + exitpoint.host + ":" + exitpoint.port + exitpoint.contextPath;
    }
    exitpoint.fullUrl = fullUrl;
}

function addExitpoint(map, exitpoint, configuration) {
    exitpoint.code = exitpoint._attributes.code;
    exitpoint.type = "exitpoint";
    exitpoint.contextPath = exitpoint['context-path'];
    addFullUrl(exitpoint);

    map[exitpoint._attributes.code] = exitpoint;
    if (exitpoint['invm-entry-point-ref']) {
        const entrypoint = configuration.entrypointmap[exitpoint['invm-entry-point-ref']];
        exitpoint.invmEntryPoint = entrypoint;
        configuration.connected[exitpoint._attributes.code] = entrypoint;
    }
    const config_parameters = [];
    if (exitpoint.properties) {
        if (isIterable(exitpoint.properties.property)) {
            for (const prop of exitpoint.properties.property) {
                // console.log(prop);
                config_parameters.push(prop);
            }
        } else {
            // console.log(exitpoint.properties.property);
            config_parameters.push(exitpoint.properties.property);
        }
    }
    exitpoint.config_parameters = config_parameters;
    const transport_parameters = [];
    if (exitpoint.attributes) {
        if (isIterable(exitpoint.attributes.attribute)) {
            for (const prop of exitpoint.attributes.attribute) {
                transport_parameters.push(prop);
                if(prop.name === 'selector.filetunnel')
                    exitpoint.selectorFiletunnel = prop.value.replace('const://','');
            }
        } else {
            transport_parameters.push(exitpoint.attributes.attribute);
        }
    }
    exitpoint.transport_parameters = transport_parameters;

    configuration.exitpoints.push(exitpoint);
}

/**
 * Build exit point map
 * When an exitpoint is connected to an entry point
 *  add the exitpointcode to the connected map with the
 * @param configuration
 * @returns {{}}
 */
function buildExitPointMap(configuration) {
    const map = {};
    const exitPoints = configuration['exit-points']['exit-point'];
    configuration.exitpoints = [];
    if (isIterable(exitPoints)) {
        for (const exitpoint of exitPoints) {
            addExitpoint(map, exitpoint, configuration);
        }
    } else {
        addExitpoint(map, exitPoints, configuration);
    }

    return map;
}

function getExitpointsArray(config) {
    const result = [];
    if (config !== undefined && config.exitpointmap !== undefined) {
      const arr = Array.from(config.exitpointmap)
        for (let [key, value] of Object.entries(config.exitpointmap)) {
        result.push(value);
      };
    }
    return result;
  }

  export { buildExitPointMap, getExitpointsArray };