import isIterable from "./helper";
import { findEntryPoints } from "./entrypoints";
import { addVariable } from "./variables";
import { addDeliveryPoint } from "./deliverypoints";

function buildTunnels(configuration) {
    const tunnels = [];
    let tunnelNum = 0;
    // there could be one tunnel then tunnels.tunnel is no array but single object.
    if (isIterable(configuration.tunnels.tunnel)) {
        for (const tunnel of configuration.tunnels.tunnel) {
            tunnel.tunnelnum = tunnelNum++;
            resolveTunnelObject(tunnel, configuration);
            tunnels.push(tunnel);
        }
    } else {
        const tunnel = configuration.tunnels.tunnel;
        tunnel.tunnelnum = tunnelNum++;
        resolveTunnelObject(tunnel, configuration);
        tunnels.push(tunnel);
    }

    return tunnels;
}

function resolveTunnelObject(tunnel, configuration) {
    tunnel.code = tunnel._attributes.code;
    tunnel.name = tunnel.common.name;
    tunnel.type = "tunnel";
    tunnel.selected = false;
    tunnel.entrypoints = findEntryPoints(tunnel, configuration)

    if (typeof tunnel.description !== "string" || tunnel.description === undefined) {
        tunnel.description = '';
    }

    if (tunnel.response['profile-ref'] !== undefined) {
        tunnel.responseProfile = configuration.outgoingProfilesMap[tunnel.response['profile-ref']]
    }

    if (tunnel.response['fault-messagetransformer-ref'] !== undefined) {
        tunnel.faultTransformer = { template: configuration.messageTransformerMap[tunnel.response['fault-messagetransformer-ref']] };
    }

    let runtimevars = [];
    let rtv_num = 1;
    //check if we have any runtimevars befor iterarting

    if (tunnel['runtime-variables'] && tunnel['runtime-variables']['variable']) {
        if (isIterable(tunnel['runtime-variables']['variable'])) {
            for (const rtv of tunnel['runtime-variables']['variable']) {
                if (rtv.value !== 'AQ==') {
                    rtv.order = rtv['order-number'];
                    rtv.jsKey = tunnel.code + '_' + rtv.order
                    addVariable(rtv, tunnel, configuration);
                    runtimevars.push(rtv);
                }
            }
        }
        else {
            const rtv = tunnel['runtime-variables']['variable'];
            rtv.order = rtv['order-number'];
            rtv.jsKey = tunnel.code + '_' + rtv.order
            addVariable(rtv, tunnel, configuration);
            runtimevars.push(rtv);
        }
    }
    tunnel.runtimeVariables = runtimevars;
    tunnel.numberOfRuntimeVariables = rtv_num;

    //itterate deliverypoints
    const deliverypoints = []
    if (tunnel.deliverypoints !== undefined) {

        if (tunnel.deliverypoints.deliverypoint._attributes === undefined) {
            for (const deliverypoint of tunnel.deliverypoints.deliverypoint) {
                const dp = addDeliveryPoint(deliverypoint, tunnel, configuration)
                deliverypoints.push(dp)
            }
        } else {
            const deliverypoint = tunnel.deliverypoints.deliverypoint;
            const dp = addDeliveryPoint(deliverypoint, tunnel, configuration)
            deliverypoints.push(dp)
        }

    }
    tunnel.deliverypoints = deliverypoints

    if (tunnel.common['res-async'] === 'true') {
        const asyncExitpoint = configuration.exitpointmap[tunnel.common['res-async-exitpoint-ref']];
        tunnel.asyncExitpoint = asyncExitpoint;
    }

    //connect the exitpoints to the tunnels
    //so we have an exitpoint that identifies a tunnel by its transport parameters
    if (tunnel['tunnel-identification']['transport-params']) {
        console.log(tunnel.name + " TRANSPORT ID")
        if (isIterable(tunnel['tunnel-identification']['transport-params']['transport-param'])) {
            for (const param of tunnel['tunnel-identification']['transport-params']['transport-param']) {
                console.log(param.name)
            }
        } else {
            const param = tunnel['tunnel-identification']['transport-params']['transport-param']
            console.log(param.name)
        }
    }
}

export { buildTunnels }