import React, { useState, useEffect } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import Profile from "./Profile";

library.add(faCaretRight);

export default function Profiles({ data, type }) {
  const [itemVisible, setItemVisiblity] = useState(false);
  const [visibleItems, setVisibleEItems] = useState([]);
  if (data === undefined) data = []

  useEffect(() => {
    if (itemVisible) {
      setVisibleEItems(data)
    }
  }, [itemVisible, data]);

  if (!type)
    type = 'Profiles';

  return (
    <div className='mb-1' hidden={!(data.length > 0)}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge className='badge-fixed' bg="secondary">{data.length} {type}</Badge>
      <Collapse in={itemVisible}>
        <div className=' ms-4'>
          {visibleItems.map((profile) => (
            <div key={profile.code}>
              <div className='row  ${varVisible ? "active" : "" }'>
                <Profile data={profile} />
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}

// function getTunnel(functionObj, showtunnel) {
//   if (showtunnel) {
//     if (functionObj !== undefined && functionObj.tunnel !== undefined && functionObj.tunnel.code !== undefined) {
//       return <Tunnel data={functionObj.tunnel} />
//     }
//   }
//   return
// }