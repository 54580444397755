import React, { useState, useEffect } from "react";
import { FormGroup, FormControl } from 'react-bootstrap';

import { getArrayFromHash, hasVar } from "../../helpers/uitils";

import { getMappingsArray } from "../../helpers/mappings";
import { getEntrypointsArray } from "../../helpers/entrypoints";
import { getExitpointsArray } from "../../helpers/exitpoints";

import Tunnels from "../../components/Opentunnel/Tunnels";
import Entrypoints from "../../components/Opentunnel/Entrypoints";
import Mappings from "../../components/Opentunnel/Mappings";
import AllTransformers from "../../components/Opentunnel/AllTransformers";
import Namespaces from "../../components/Opentunnel/Namespaces";
import Exitpoints from "../../components/Opentunnel/ExitPoints";
import FileTunnels from "../../components/Opentunnel/FileTunnels";
import Functions from "../../components/Opentunnel/Functions";
import Profiles from "../../components/Opentunnel/Profiles";


var num = 0

export default function HomeTab({ config }) {
  const [searchText, setSearchText] = useState([]);

  useEffect(() => {
    // console.log('hometab ' + num++)
  });

  function getItem(name) {
    if (config) {
      switch (name) {
        case "tun": return config.allTunnels;
        case "ftun": return config.filetunnels;
        case "enp": return getEntrypointsArray(config)
        case "exp": return getExitpointsArray(config);
        case "map": return getMappingsArray(config);
        case "ogp": return getArrayFromHash(config.outgoingProfilesMap);
        case "trf": return config.allTransformers;
        case "fun": return getArrayFromHash(config.functionsMap);
        case "nsp": return config.namespaces;
      }
    }
    return []
  }

  return (
    <div className='nowrap'>
      <FormGroup className="mt-4 mb-4">
        <FormControl
          id="outlined-basic"
          variant="outlined"
          label="Search"
          type="text"
          placeholder="Search"
          hidden={getItem("tun").length === 0}
          onChange={e => setSearchText(e.target.value.toLowerCase())}
        />
      </FormGroup>
      <Tunnels data={getItem("tun").filter(x => x.name.toLowerCase().indexOf(searchText) !== -1)} hidden={getItem("tun").length === 0} />
      <FileTunnels data={getItem("ftun").filter(x => x.name.toLowerCase().indexOf(searchText) !== -1)} hidden={getItem("ftun").length === 0} />
      <Entrypoints data={getItem("enp").filter(x => x.name.toLowerCase().indexOf(searchText) !== -1)} expand={true} showtunnel={true} />
      <Exitpoints data={getItem("exp").filter(x => x.name.toLowerCase().indexOf(searchText) !== -1)} expand={true} />
      <Functions data={getItem("fun").filter(x => x.name.toLowerCase().indexOf(searchText) !== -1)} />
      <Mappings data={getItem("map").filter(x => x.name.toLowerCase().indexOf(searchText) !== -1)} />
      <Profiles data={getItem("ogp").filter(x => x.name.toLowerCase().indexOf(searchText) !== -1)} />
      <AllTransformers data={getItem("trf").filter(x => x.name.toLowerCase().indexOf(searchText) !== -1 ||
        x.class.toLowerCase().indexOf(searchText) !== -1 ||
        hasVar(x.variables, searchText))
      } />
      <Namespaces data={getItem("nsp").namespace} name={"namespaces"} />

    </div>
  )
}
