import React, { useState } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Transformers from "./Transformers";
import BadgeExtended from "../react/BadgeExtended";

export default function Profile({ data, type }) {
  const [itemVisible, setItemVisiblity] = useState(false);

  if (!type)
    type = "Profile";

  function getProfileName(profile) {
    if (profile !== undefined) {
      return profile.name
    }
  }

  function getTemplates(profile) {
    if (profile !== undefined) {
      return profile.transformers
    }
    return []
  }

  return (
    <div className='nowrap' hidden={data === undefined}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge className="me-2 badge-120" bg="secondary" >{type}</Badge>
      <Badge className="ms-2" >{getTemplates(data).length} Transformer(s)</Badge>
      <BadgeExtended className="ms-2 e-badge-name text-dark" bg="" tooltip="click to copy" copytext={getProfileName(data)}>{getProfileName(data)}</BadgeExtended>
      <Collapse in={itemVisible}>
        <div className="ms-4" >
          <Transformers data={getTemplates(data)} />
        </div>
      </Collapse>
    </div>
  )
}
