import React, { useState } from "react";
import { Button, Badge, Modal, ModalBody, ModalHeader, ModalTitle, Tabs, Tab, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import Variables from "./Variables";
import BadgeExtended from "../react/BadgeExtended";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import 'ace-builds/src-noconflict/mode-ftl'
import 'ace-builds/src-noconflict/mode-xml'
import 'ace-builds/src-noconflict/theme-github'
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver"
import Dependencies from "./Dependencies";

export default function AllTransformers({ data }) {
  const [transformerVisible, setTransformersVisiblity] = useState(false);
  const isTransformer = data ? true : false
  const num = isTransformer ? data.length : 0
  return (
    <div className='nowrap mb-1' hidden={!(data.length > 0)}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${transformerVisible ? "active" : ""}`}
        onClick={(e) => {
          setTransformersVisiblity((v) => !v);
        }} />
      <Badge className='badge-fixed me-2' bg="secondary" >{data.length} Transformers</Badge>
      <Collapse in={transformerVisible}>
        <div className=' ms-4'>
          {data.map((transformer) => (
            <div key={transformer.code}>
              <BareTransformer data={transformer} />
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}

function getDependencies(dependencies) {
  if (dependencies !== undefined) {
    return (
      <div>
        <Dependencies data={dependencies} />
      </div>
    )
  }
}

function getTransformerContent(template) {
  if (template.content !== undefined)
    return <TransformerContent data={template} />
}

function getNumVariables(vars) {
  if (vars !== undefined && vars.length > 0) {
    return <Badge className="ms-2">{vars.length} Variables</Badge>
  }
}

function getNumDependencies(vars) {
  if (vars !== undefined && vars.length > 0) {
    return <Badge className="ms-2">{vars.length} Dependencies</Badge>
  }
}

export function BareTransformer({ data }) {
  const [transformerVisible, setTransformerVisiblity] = useState(false);
  return (
    <div >
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${transformerVisible ? "active" : ""}`}
        onClick={(e) => {
          setTransformerVisiblity((v) => !v);
        }} />
      <Badge bg="secondary" >Transformer</Badge>
      {/* <Badge className="ms-2" bg="success">{data.conditional}</Badge> */}
      <Badge className="ms-2 badge-90" bg="primary">{data.class}</Badge>
      <Badge className='ms-2' bg="danger" hidden={data.copyright === false}>Copyright</Badge>
      <Badge className='ms-2 badge-150'>{data['target-mimetype']}</Badge>
      {getNumVariables(data.variables)}
      {getNumDependencies(data.dependenciesList)}
      <BadgeExtended className="ms-2 e-badge-name text-dark" bg="" tooltip="click to copy" action="copy">{data.name}</BadgeExtended>
      <Collapse in={transformerVisible}>
        <div className="ms-4">
          <Variables data={data.variables} name={data.name} />
          {getDependencies(data.dependenciesList)}
          {getTransformerContent(data)}
        </div>
      </Collapse>
    </div>
  )
}

function TransformerContent({ data }) {
  const [contentVisible, setContentVisiblity] = useState(false);
  const [content, setContent] = useState(data.decodedcontent);
  const [tabkey, setTabkey] = useState('content');

  const handleClose = () => setContentVisiblity(false);

  function onChange(newValue) {
    data.decodedcontent = newValue;
    setContent(newValue);
  }

  //update content
  function handleSave() {
    data.decodedcontent = content;
    setContentVisiblity(false);
  }

  function handleExportClick() {
    const filename = data.name.replace(/\s+|#/g, '_');
    if (tabkey === 'variables') {
      const result = []
      data.variables.map((variable) => (
        result.push(variable.name + '=' + variable.value)
      ))
      var blob = new Blob([result.join("\n")], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, filename + ".tunnelvars");
    }
    if (tabkey === 'content') {

      blob = new Blob([data.decodedcontent], { type: "text/plain;charset=utf-8" });
      // console.log(data.class)
      var ext = '.txt'
      if (data.class === 'freemarker') {
        ext = '.ftl'
      }
      if (data.class === 'xslt') {
        ext = '.xsl'
      }
      if (data.class === 'groovy') {
        ext = '.groovy'
      }
      FileSaver.saveAs(blob, filename + ext);
    }
  }

  function handleExportB64Click() {
    const filename = data.name.replace(/\s+|#/g, '_');
    if (tabkey === 'variables') {
      const result = []
      data.variables.map((variable) => (
        result.push(variable.name + '=' + variable.value)
      ))
      var blob = new Blob([result.join("\n")], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, filename + ".tunnelvars");
    }
    if (tabkey === 'content') {
      var base64 = require('base-64');
      var encodedContent = base64.encode(data.decodedcontent);

      blob = new Blob([encodedContent], { type: "text/plain;charset=utf-8" });
      // console.log(data.class)
      var ext = '.b64'

      FileSaver.saveAs(blob, filename + ext);
    }
  }

  return (
    <div>
      <Badge bg="secondary" onClick={(e) => {
        setContentVisiblity((v) => !v);
      }}>Content</Badge>
      <Badge className="ms-2" bg="primary">{data.class}</Badge>
      <Badge
        className="ms-2"
        bg="info"
        onClick={(e) => {
          handleExportClick();
        }}>Export</Badge>
      <div>  {contentVisible && (
        <Modal show={contentVisible} onHide={handleClose} backdrop="true" size="xl" height="100%">
          <ModalHeader closeButton>
            <ModalTitle>{data.name}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="mb-2">
              <Badge className='me-2' bg="info">{data.class}</Badge>
              <Badge className='me-2' bg="success">{data.conditional}</Badge>
              <Badge className='me-2' bg="secondary" hidden={data.modifiable === false}>Modifiable</Badge>
              <Badge className='me-2' bg="danger" hidden={data.copyright === false}>Copyright</Badge>
              <Badge className='me-2' hidden={!data['target-contenttype']}>ContentType: {data['target-contenttype']}</Badge>
              <Badge className='me-2' hidden={!data['target-mimetype']}>MimeType: {data['target-mimetype']}</Badge>
            </div>
            <Tabs
              activeKey={tabkey}
              id="template-tabs"
              onSelect={(k) => setTabkey(k)}
              className="mb-3">
              <Tab eventKey="details" title="Details" tabClassName={data.descr === undefined ? 'd-none' : ''}>
                <div className="mt-2 border rounded-2 p-2">{data.descr}</div>
              </Tab>
              <Tab eventKey="content" title="Content">
                <AceEditor
                  mode={data.extension}
                  theme="tomorrow"
                  onChange={onChange}
                  name="UNIQUE_ID_OF_DIV"
                  // editorProps={{ $blockScrolling: true }}
                  value={content}
                  // value="test"
                  width="100%"
                /></Tab>
              <Tab eventKey="variables" title="Variables" tabClassName={data.variables.length === 0 ? 'd-none' : ''}>
                <Variables data={data.variables} name={data.name} />
              </Tab>
              <Tab eventKey="dependencies" title="Dependencies" tabClassName={data.dependenciesList.length === 0 ? 'd-none' : ''}>
                <Dependencies data={data.dependenciesList} />
              </Tab>
            </Tabs>
          </ModalBody>
          <Modal.Footer>
            <Button variant="danger" onClick={handleExportClick} hidden={(tabkey === 'details')}>
              Export
            </Button>
            <Button variant="secondary" onClick={handleExportB64Click} hidden={(tabkey !== 'content')}>
              Export Base64
            </Button>
            <Button variant="primary" onClick={handleSave} hidden={true}>
              Save Changes
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>
      )}
      </div>
    </div>

  )
}
