import * as React from 'react';
import { CustomNodeModel } from './CustomNodeModel.ts';
import { CustomNodeWidget } from './CustomNodeWidget.tsx';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { TunnelNodeWidget } from './TunnelNodeWidget.tsx';
import { EntrypointNodeWidget } from './EntrypointNodeWidget.tsx';
import { ExitpointNodeWidget } from './ExitpointNodeWidget.tsx';
import { TransformerNodeWidget } from './TransformerNodeWidget.tsx';
import { MappingsNodeWidget } from './MappingsNodeWidget.tsx';
import { FunctionNodeWidget } from './FunctionNodeWidget.tsx';
import { FileTunnelNodeWidget } from './FileTunnelNodeWidget.tsx';

export class CustomNodeFactory extends AbstractReactFactory<CustomNodeModel, DiagramEngine> {
	constructor() {
		super('custom');
	}

	generateReactWidget(event): JSX.Element {
		const model = event.model;
		const nodetype = model.getOptions().nodetype;
		// console.log(nodetype);
		switch (nodetype) {
			case 'tunnel':
				return <TunnelNodeWidget engine={this.engine} node={model} />;
			case 'filetunnel':
				return <FileTunnelNodeWidget engine={this.engine} node={model} />;
			case 'entrypoint':
				return <EntrypointNodeWidget engine={this.engine} node={model} />;
			case 'exitpoint':
				return <ExitpointNodeWidget engine={this.engine} node={model} />;
			case 'transformer':
				return <TransformerNodeWidget engine={this.engine} node={model} />;
			case 'mapping':
				return <MappingsNodeWidget engine={this.engine} node={model} />;
			case 'function':
				return <FunctionNodeWidget engine={this.engine} node={model} />;
			default:
				return <CustomNodeWidget engine={this.engine} node={model} />;
		}
	}

	generateModel(event): CustomNodeModel {
		return new CustomNodeModel();
	}
}
