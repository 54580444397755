import React, { useState, useRef, useEffect } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import * as _ from 'lodash';
import { BaseNodeComponent } from './BaseNodeComponent.tsx';
import eventBus from '../../../../../EventBus.js';

export class FileTunnelNodeWidget extends BaseNodeComponent {
	componentDidMount() {
		if (this.needsToRegister) {
			this.needsToRegister = false;
			// eventBus.on("tunnel-select",this.tunnelselected);
			// eventBus.on("tunnel-select", (data) => {
			// 	if (data.code === this.dataNode.code) {
			// 		console.log(this.node)
			// 		this.node.setHideDetails(!this.dataNode.selected)
			// 		this.setSelected(this.dataNode.selected);
			// 		this.forceUpdate();
			// 	}
			// });
			// eventBus.on('tunnel-color', (data) => {
			// 	if (data.code === this.dataNode.code) {
			// 		this.node.setColor(data.color);
			// 		this.forceUpdate();
			// 	}
			// });
		}
	}

	toggleFileTunnelDetails(node) {
		this.toggleDetails(node);
		eventBus.dispatch('filetunnel-selected', { code: this.dataNode.code, selected: !node.needsToHide() });
	}

	render() {
		return (
			<div className='tunnel-widget tunnel-shadow' style={{ background: this.props.node.getColor() }}>
				<div className='title'>
					<div className='title-name'>FileTunnel {this.props.node.getName()}</div>
					<button className='m-2 detail-button sm' onClick={() => this.toggleFileTunnelDetails(this.props.node)}>details</button>
				</div>
				<div className='description'>{this.getDescription()}</div>
				<div className='ports'>
					<div className='ports-container'>{_.map(this.props.node.getInPorts(), this.generatePort)}</div>
					<div className='ports-container'>{_.map(this.props.node.getOutPorts(), this.generatePort)}</div>
				</div>
				<div hidden={this.props.node.needsToHide()}>
					<div className='common'>
						<strong>General</strong>
						<table>
							<tbody>
								<tr><td>delete-temp-files</td><td><input type='checkbox' disabled checked={(/true/).test(this.props.node.getUserData()['delete-temp-files'])} /></td></tr>
								<tr><td>audit-retention (sec)</td><td><input value={this.props.node.getUserData()['audit-retention']} disabled /></td></tr>
								<tr><td>number-of-retries</td><td><input value={this.props.node.getUserData()['number-of-retries']} disabled /></td></tr>
								<tr><td>retry-period (sec)</td><td><input value={this.props.node.getUserData()['retry-period']} disabled /></td></tr>
							</tbody>
						</table>
					</div>
					<div className='variables' hidden={!this.getVariables()}>
						<table>
							<tbody>
								<tr><th colSpan={3}>TRANSPORT PARAMETERS</th></tr>
								<tr><th>order</th><th>name</th><th>value</th></tr>
								{this.getVariables().map((variable) => (
									<tr key={variable.name}><td>{variable.order}</td><td>{variable.name}</td><td>{variable.value.toString()}</td></tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
