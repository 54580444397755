import isIterable from "./helper";

function updateDependency(dependency, configuration) {
    // console.log('UPADET DEP ');
    // console.log(dependency);
    if (dependency['transformation-mapping-ref'] !== undefined) {
        // console.log('mapping DEP')
        dependency.type = 'mapping';
        dependency.mapping = configuration.mappings.transformationMappings[dependency['transformation-mapping-ref']];
        // console.log(dependency)
    } else if (dependency['outgoing-profile-ref'] !== undefined) {
        dependency.type = 'profile';
        dependency.profile = configuration.outgoingProfilesMap[dependency['outgoing-profile-ref']];
    } else if (dependency['exit-point-ref'] !== undefined) {
        dependency.type = 'exitpoint';
        const exitpoint = configuration.exitpointmap[dependency['exit-point-ref']];
        dependency.exitpoint = exitpoint;
    } else if (dependency['function-ref'] !== undefined) {
        // console.log('function DEP')
        dependency.type = 'function';
        dependency.function = configuration.functionsMap[dependency['function-ref']];
        // console.log(dependency)
    } else {
        dependency.type = 'unknown';
    }
    // console.log('-----');
}

function updateTransformersForDependencies(configuration) {
    // console.log('UPDATE transformers')
    for (const mt_code in configuration.messageTransformerMap) {
        const mt = configuration.messageTransformerMap[mt_code];
        var depNum = 0;
        const dependenciesList = [];
        if (mt.dependencies) {
            // console.log('FOUND DEP for: ' + mt.name)
            if (isIterable(mt.dependencies.dependency)) {
                for (const dependency of mt.dependencies.dependency) {
                    dependency.jsKey = mt.code + '_' + depNum++;
                    updateDependency(dependency, configuration)
                    dependenciesList.push(dependency);
                }
            } else {
                const dependency = mt.dependencies.dependency;
                dependency.jsKey = mt.code + '_' + depNum++;
                updateDependency(dependency, configuration)
                dependenciesList.push(dependency);
            }
        }
        mt.dependenciesList = dependenciesList;
    }
}

function updateFunctionsForDependencies(configuration) {
    for (const funct_code in configuration.functionsMap) {
        const funct = configuration.functionsMap[funct_code];
        var depNum = 0;
        const dependenciesList = [];
        if (funct.dependencies) {
            if (isIterable(funct.dependencies.dependency)) {
                for (const dependency of funct.dependencies.dependency) {
                    dependency.jsKey = funct.code + '_' + depNum++;
                    updateDependency(dependency, configuration)
                    dependenciesList.push(dependency);
                }
            } else {
                const dependency = funct.dependencies.dependency;
                dependency.jsKey = funct.code + '_' + depNum++;
                updateDependency(dependency, configuration)
                dependenciesList.push(dependency);
            }
        }
        funct.dependenciesList = dependenciesList;
    }
}



export { updateTransformersForDependencies, updateFunctionsForDependencies }