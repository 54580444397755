import React, { useState, useEffect } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { GithubPicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Entrypoints from "./Entrypoints";
import Variables from "./Variables";
import Deliverypoints from "./Deliverypoints";
import Profile from "./Profile";
import AsyncExitpoint from "./AsyncExitpoint";
import { Transformer } from "./Transformers";
import BadgeExtended from "../react/BadgeExtended";

import eventBus from "../../EventBus";
import { nodeColors } from "../ui/diagrams/TunnelModel.ts";

const colorPreset = [
  '#ab0e0e',
  '#ff5a19',
  '#e4b600',
  '#58b358',
  '#00642d',
  '#0060c7',
  '#009dff',
]

export default function Tunnel({ data }) {
  const [itemVisible, setItemVisiblity] = useState(false);
  const [selected, setSelected] = useState(data.selected);
  const [tunnelColor, setTunnelColor] = useState(data.color ??= nodeColors['tunnel']);
  const [colorpickerHide, setColorpickerHide] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);

  function copyText(text) {
    // console.log(text);
    navigator.clipboard.writeText(text);
  }

  function handleColorClick(e) {
    setColorpickerHide(!colorpickerHide);
  };

  //set all eventhandlers 
  useEffect(() => {
    if(isRegistered) {
      return;
    }
    // console.log('Register events for TunnelComponent ' + data.code)
    // console.log('Tunnel' + data.tunnelnum)
    eventBus.on('tunnel-color', (newdata) => {
      if (newdata.code === data.code) {
        setTunnelColor(newdata.color);
      }
    });
    eventBus.on('tunnel-select', (newdata) => {
      if (newdata.code === data.code) {
        setSelected(newdata.selected);
      }
    });
    eventBus.on('diagramtunnel-selected', (newdata) => {
      if (newdata.code === data.code) {
        data.selected = newdata.selected;
        setSelected(newdata.selected);
        setItemVisiblity(newdata.selected);
      }
    });
    setIsRegistered(true);
  });

  function handleColorChange(color) {
    setTunnelColor(color.hex)
    data.color = color.hex;
    setColorpickerHide(!colorpickerHide);
    eventBus.dispatch("tunnel-color", data);
  }

  function setSelection(e) {
    setSelected(e.target.checked);
    data.selected = e.target.checked;
    eventBus.dispatch("tunnel-select", data);
  }

  function showTunnel(e){
    eventBus.dispatch("node-show", data);
  }

  function getDeliverypointsSpecs(deliverypoints) {
    if (deliverypoints) {
      var headerText = Object.values(deliverypoints).length + " Deliverypoints";
      var bodyText = "<ol> "
      Object.values(deliverypoints).map(dp => {
        bodyText += "<li>"+dp.name + "</li>";
      })
      bodyText += "</ol>"
      // if (data.invmEntryPoint.tunnel) {
      //   bodyText += "Tunnel: " + data.invmEntryPoint.tunnel.name;
      // }
      // console.log(data)
      return {
        header: headerText,
        body: bodyText
      }
    }
    return { header: "", body: "" }
  }

  return (
    <div className='nowrap' >
      <input className="me-2" type="checkbox" id={data.code} checked={selected} onChange={setSelection} />
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge bg="secondary" >Tunnel #{data.tunnelnum}
        <button className="btn-circle" type="button" style={{ backgroundColor: tunnelColor }} onClick={handleColorClick} />
      </Badge>
      <div className="popover ms-4" hidden={colorpickerHide}>
        <GithubPicker colors={colorPreset} color={tunnelColor} onChange={handleColorChange} />
      </div>
      <Badge className="ms-2 badge-100">{data.entrypoints.length} Entrypoint(s)</Badge>
      <BadgeExtended className="ms-2 badge-120" popover={getDeliverypointsSpecs(data.deliverypoints)}>{getDelioveryPoints(data.deliverypoints).length} Deliverypoint(s)</BadgeExtended>
      {getVariables(data.runtimeVariables)}
      <Badge className="ms-2" bg="warning" hidden={data.responseProfile === undefined}>responseProfile</Badge>
      <BadgeExtended className="e-badge-name text-dark ms-2" bg="" tooltip="click to copy" copytext={data.name}>{data.name}</BadgeExtended>
      {/* <div className="e-badge-name text-dark size_3 ms-2">{data.name}</div> */}
      <Collapse in={itemVisible}>
        <div> 
           {/* {itemVisible && ( */}
          <div className='row  ms-4'>
            <TunnelContent data={data} />
          </div>
        {/* )} */}
        </div>
      </Collapse>
    </div>
  )
}

function getVariables(variables) {
  if (variables !== undefined && variables.length > 0) {
    return <Badge className="ms-2 badge-90">{variables.length} Variables</Badge>
  }
}

function getDelioveryPoints(dp) {
  if (dp === undefined)
    return []
  return dp
}

function getAsyncExitpoint(exitpoint) {
  if (exitpoint !== undefined)
    return <AsyncExitpoint data={exitpoint} />
}

function getFaultTransformer(faulttransformer) {
  if (faulttransformer !== undefined)
    return <Transformer data={faulttransformer} type="Fault transformer" />
}

function TunnelContent({ data }) {
  return (
    <div >
      <Entrypoints data={data.entrypoints} showtunnel={false} />
      <Variables data={data.runtimeVariables} name={data.name} />
      {getAsyncExitpoint(data.asyncExitpoint)}
      <Deliverypoints data={data.deliverypoints} />
      {getFaultTransformer(data.faultTransformer)}
      <Profile data={data.responseProfile} type="Response Profile" />
    </div>
  )
}
