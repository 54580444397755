import React, { useState } from "react";
import { Button, Badge, Modal, ModalBody, ModalHeader, ModalTitle, Tabs, Tab, Collapse } from 'react-bootstrap';
import FileSaver from "file-saver";
import Variables from "./Variables";
import Dependencies from "./Dependencies";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import 'ace-builds/src-noconflict/mode-ftl'
import 'ace-builds/src-noconflict/mode-xml'
import 'ace-builds/src-noconflict/theme-github'
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver"

export default function FunctionTransformerContent({ data }) {
    const [contentVisible, setContentVisiblity] = useState(false);
    const [content, setContent] = useState(data.script.decodedcontent);
    const [tabkey, setTabkey] = useState('content');

    // console.log(data)

    const handleClose = () => setContentVisiblity(false);

    function onChange(newValue) {
        setContent(newValue);
    }
    function handleSave() {
        data.decodedcontent = content;
        setContentVisiblity(false);
    }

    function handleExportClick() {
        const filename = data.name.replace(/\s+|#/g, '_');
        if (tabkey === 'variables') {
            const result = [];
            data.variables.map((variable) => (
                result.push(variable.name + '=' + variable.value)
            ))
            var blob = new Blob([result.join("\n")], { type: "text/plain;charset=utf-8" });
            FileSaver.saveAs(blob, filename + ".tunnelvars");
        }
        if (tabkey === 'content') {

            blob = new Blob([data.script.decodedcontent], { type: "text/plain;charset=utf-8" });
            // console.log(data.class)
            var ext = '.txt';
            if (data.class === 'freemarker') {
                ext = '.ftl';
            }
            if (data.class === 'xslt') {
                ext = '.xsl';
            }
            if (data.class === 'groovy') {
                ext = '.groovy';
            }
            FileSaver.saveAs(blob, filename + ext);
        }
    }

    return (
        <div>
            <Badge bg="secondary" onClick={(e) => {
                setContentVisiblity((v) => !v);
            }}>Content</Badge>
            <Badge className="ms-2" bg="info">{data.class}</Badge>
            <Badge className="ms-2" variant="success"
                onClick={(e) => {
                    handleExportClick();
                }}>Export</Badge>
            <div>  {contentVisible && (
                <Modal show={contentVisible} onHide={handleClose} backdrop="true" size="xl" height="100%">
                    <ModalHeader closeButton>
                        <ModalTitle>{data.name}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="mb-2">
                            <Badge className='me-2' bg="info">{data.class}</Badge>
                            <Badge className='me-2' bg="success">{data.conditional}</Badge>
                            <Badge className='me-2' bg="secondary" hidden={data.modifiable === false}>Modifiable</Badge>
                            <Badge className='me-2' bg="danger" hidden={data.copyright === false}>Copyright</Badge>
                            <Badge className='me-2' hidden={data['target-contenttype'] === undefined}>ContentType: {data['target-contenttype']}</Badge>
                            <Badge className='me-2' hidden={data['target-mimetype'] === undefined}>MimeType: {data['target-mimetype']}</Badge>
                        </div>
                        <Tabs
                            activeKey={tabkey}
                            id="template-tabs"
                            onSelect={(k) => setTabkey(k)}
                            className="mb-3">
                            <Tab eventKey="details" title="Details" tabClassName={data.descr === undefined ? 'd-none' : ''}>
                                <div className="mt-2 border rounded-2 p-2">{data.descr}</div>
                            </Tab>
                            <Tab eventKey="content" title="Content">
                                <AceEditor
                                    mode={data.extension}
                                    theme="tomorrow"
                                    onChange={onChange}
                                    name="UNIQUE_ID_OF_DIV"
                                    editorProps={{ $blockScrolling: true }}
                                    value={content}
                                    width="100%"
                                />
                            </Tab>
                            <Tab eventKey="variables" title="Variables" tabClassName={data.variables.length === 0 ? 'd-none' : ''}>
                                <Variables data={data.variables} />
                            </Tab>
                            <Tab eventKey="dependencies" title="Dependencies" tabClassName={data.dependenciesList.length === 0 ? 'd-none' : ''}>
                                <Dependencies data={data.dependenciesList} />
                            </Tab>
                        </Tabs>
                    </ModalBody>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleExportClick} hidden={(tabkey === 'details')}>
                            Export
                        </Button>
                        <Button variant="primary" onClick={handleSave} hidden={true}>
                            Save Changes
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            </div>
        </div>

    )
}
