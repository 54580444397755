
import buildMessagetransformerMap from './transformers';
import { buildFunctionNames } from './functions';
import { buildEntryPointMap } from './entrypoints';
import { buildExitPointMap } from './exitpoints';
import buildScriptsMap from './scripts';
import buildFunctionMap from './functions';
import { buildOutgoingProfilesMap } from './profiles';
import { buildTransformationMappings, buildMappingNames } from './mappings';
import { updateTransformersForDependencies, updateFunctionsForDependencies } from './dependencies';
import { buildTunnels } from './tunnels';
import { buildPlugins, updateExitpointsForFiletunnels } from './plugins';
/**
 * Build configuration from json object
 * beware that when there is only one object we have nor array
 *      when there are two object we do hav an array.
 *      therfore we check if objects are iterable
 *
 * configuration {
 *      tunnels [
 *          entrypoints []
 *          deliverypoints [
 *              expression
 *              exitpoint
 *              profile [
 *                  transformers [
 *
 *                  ]
 *              ]
 *          ]
 *      ]
 *  }
 * @param configuration
 * @returns {*}
 */
async function buildConfiguration(configuration, onProgressUpdate) {
    configuration.version = 'unknown'
    configuration.connected = {};
    if (configuration._attributes) {
        configuration.version = configuration._attributes.version;
        configuration.exportDate = configuration._attributes.generationDate;
    }
    return new Promise((resolve) => {
        const steps = [
            { done: false, progress: 5, text: "Analyze Entrypoints...", action: () => { configuration.entrypointmap = buildEntryPointMap(configuration); } },
            { done: false, progress: 10, text: "Analyze Exitpoints...", action: () => { configuration.exitpointmap = buildExitPointMap(configuration); } },
            { done: false, progress: 15, text: "Analyze Scripts...", action: () => { configuration.scriptsMap = buildScriptsMap(configuration); } },
            { done: false, progress: 20, text: "Analyze Mappings...", action: () => { configuration.functionsMap = buildFunctionMap(configuration); } },
            { done: false, progress: 25, text: "Analyze Functions...", action: () => { configuration.functionNames = buildFunctionNames(configuration); } },
            { done: false, progress: 30, text: "Analyze Plugins...", action: () => { configuration.filetunnels = buildPlugins(configuration); } },
            { done: false, progress: 35, text: "Analyze Mappings...", action: () => { buildMappings(configuration) } },
            { done: false, progress: 40, text: "Analyze Transformers...", action: () => { configuration.messageTransformerMap = buildMessagetransformerMap(configuration); } },
            { done: false, progress: 45, text: "Analyze Profiles...", action: () => { configuration.outgoingProfilesMap = buildOutgoingProfilesMap(configuration); } },
            { done: false, progress: 50, text: "Update Transformer Dependencies...", action: () => { updateTransformersForDependencies(configuration) } },
            { done: false, progress: 55, text: "Update Function Dependencies...", action: () => { updateFunctionsForDependencies(configuration) } },
            { done: false, progress: 60, text: "Update FileTunnels...", action: () => { updateExitpointsForFiletunnels(configuration) } },
            { done: false, progress: 65, text: "Build Tunnels...", action: () => { configuration.allTunnels = buildTunnels(configuration); } },
            { done: false, progress: 70, text: "Build Transformers...", action: () => {configuration.allTransformers = buildTranformers(configuration); } },
            { done: false, progress: 95, text: "Fix Namespaces...", action: () => { fixNamespaces(configuration) } },
            { done: true, progress: 100, text: "Finalising...", action: () => { console.log('done') } },
        ];

        let currentStep = 0;

        function processNextStep() {
            if (currentStep < steps.length) {
                const { progress, text, done, action } = steps[currentStep];
                action();  // Execute the current step action
                onProgressUpdate(progress, text, done);  // Update progress and text
                currentStep++;
                // Yield control to the UI thread and queue the next step
                setTimeout(processNextStep, 300);
            } else {
                resolve(configuration);
            }
        }

        processNextStep();
    });

}

function buildMappings(configuration) {
    if (configuration.mappings) {
        configuration.mappings.transformationMappings = buildTransformationMappings(configuration);
        configuration.mappingNames = buildMappingNames(configuration);
    } else {
        //does not exist so create empty structure
        configuration.mappings = { transformationMappings: [] };
        configuration.mappingNames = {};
    }
}

function fixNamespaces(configuration) {
    // updateTransformersForDependencies(configuration);
    // updateFunctionsForDependencies(configuration);
    // updateExitpointsForFiletunnels(configuration);
    // fixOutgoingProfilesMap(configuration);

    // configuration.allTunnels = buildTunnels(configuration);
    // configuration.allTransformers = buildTranformers(configuration);

    if (configuration.namespaces === undefined) {
        configuration.namespaces = {};
        configuration.namespaces["namespace"] = [];
    }

}

function buildTranformers(configuration) {
    const transformers = [];
    // console.log(configuration.messageTransformerMap)
    for (const transformer in configuration.messageTransformerMap) {
        // console.log(configuration.messageTransformerMap[transformer]);
        transformers.push(configuration.messageTransformerMap[transformer])
    }
    return transformers;
}



export { buildConfiguration }