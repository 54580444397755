import React, { useState, useEffect } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import Tunnel from "./Tunnel";
import Variables from "./Variables";
import Exitpoint from "./Exitpoint";

library.add(faCaretRight);

export default function Exitpoints({ data, expand, showtunnel, type }) {
  const [exitpointVisible, setExitpointVisiblity] = useState(false);
  const [visibleExitpoints, setVisibleExitypoints] = useState([]);
  if (data === undefined) data = []

  useEffect(() => {
    if (exitpointVisible) {
      setVisibleExitypoints(data)
    }
  }, [exitpointVisible, data]);

  if (!type)
    type = 'Exitpoints';

  return (
    <div className='mb-1' hidden={!(data.length > 0)}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${exitpointVisible ? "active" : ""}`}
        onClick={(e) => {
          setExitpointVisiblity((v) => !v);
        }} />
      <Badge className='badge-fixed' bg="secondary" >{data.length} {type}</Badge>
      <Collapse in={exitpointVisible}>
        <div className=' ms-4'>
          {visibleExitpoints.map((exitpoint) => (
            <div key={exitpoint.code}>
              <div className='row  ${varVisible ? "active" : "" }'>
                <Exitpoint data={exitpoint} expand={expand} showtunnel={showtunnel} />
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}

function getTunnel(exitpoint, showtunnel) {
  if (showtunnel) {
    if (exitpoint !== undefined && exitpoint.tunnel !== undefined && exitpoint.tunnel.code !== undefined) {
      return <Tunnel data={exitpoint.tunnel} />
    }
  }
  return
}