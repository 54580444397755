import { MouseEvent, TouchEvent } from 'react';
import {
	SelectingState,
	State,
	Action,
	InputType,
	ActionEvent,
	DragCanvasState
} from '@projectstorm/react-canvas-core';
import { PortModel, DiagramEngine, DragDiagramItemsState } from '@projectstorm/react-diagrams-core';

export class DefaultState extends State<DiagramEngine> {
	dragCanvas: DragCanvasState;
	dragItems: DragDiagramItemsState;

	constructor() {
		super({ name: 'starting-state' });
		this.childStates = [new SelectingState()];
		this.dragCanvas = new DragCanvasState();
		this.dragItems = new DragDiagramItemsState();

		// determine what was clicked on
		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event: ActionEvent<MouseEvent>) => {
					const element = this.engine.getActionEventBus().getModelForEvent(event);

					// the canvas was clicked on, transition to the dragging canvas state
					if (!element) {
						this.transitionWithEvent(this.dragCanvas, event);
					}
					// initiate dragging a new link
					else if (element instanceof PortModel) {
						return;
					}
					// move the items (and potentially link points)
					else {
						this.transitionWithEvent(this.dragItems, event);
					}
				}
			})
		);

		// touch drags the canvas
		this.registerAction(
			new Action({
				type: InputType.TOUCH_START,
				fire: (event: ActionEvent<TouchEvent>) => {
					this.transitionWithEvent(new DragCanvasState(), event);
				}
			})
		);

		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (event: ActionEvent<MouseEvent>) => {
					const element = this.engine.getActionEventBus().getModelForEvent(event);

					if (element instanceof PortModel) {
						// console.log(event)
						const element = this.engine.getActionEventBus().getModelForEvent(event);
						const {
							event: { clientX, clientY }
						} = event;
						const ox = this.engine.getModel().getOffsetX();
						const oy = this.engine.getModel().getOffsetY();

						if (element instanceof PortModel) {
							// console.log(element);
							for (var i in element.links) {
								const link = element.links[i];
								link.getOptions().color = 'red';
								link.getOptions().width = 6;
								// console.log(link)
								if (!element.getOptions().in) {
									const targetNode = link.getTargetPort().getParent();
									targetNode.setSelected(true);
									this.engine.zoomToFitSelectedNodes({ margin: 200 })
									targetNode.setSelected(false);
								} else {
									const sourceNode = link.getSourcePort().getParent();
									sourceNode.setSelected(true);
									this.engine.zoomToFitSelectedNodes({ margin: 200 })
									sourceNode.setSelected(false);
								}
							}
						}
					}
				}
			})
		);
	}
}
