import React, { useState } from "react";
import { Badge, Modal, ModalBody, ModalHeader, ModalTitle, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Variables from "./Variables";
import Dependencies from "./Dependencies";
import FunctionTransformerContent from "./FunctionTransformerContent";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import BadgeExtended from '../react/BadgeExtended';

function getNumVariables(vars) {
    if (vars !== undefined && vars.length > 0) {
        return <Badge className="ms-2">{vars.length} Variables</Badge>
    }
}

function getNumDependencies(vars) {
    if (vars !== undefined && vars.length > 0) {
        return <Badge className="ms-2">{vars.length} Dependencies</Badge>
    }
}

function getDependencies(dependencies) {
    if (dependencies !== undefined) {
        return (
            <div>
                <Dependencies data={dependencies} />
            </div>
        )
    }
}

function getTransformerContent(funct) {
    if (funct.script.decodedcontent !== undefined)
        return <FunctionTransformerContent data={funct} />
}

export default function Function({ data }) {
    const [itemVisible, setItemVisiblity] = useState(false);
    const handleClose = () => setItemVisiblity(false);
    function getName() {
        if (data !== undefined) {
            return data.name;
        }
        return undefined;
    }
    function onChange(newValue) {
        console.log("change", newValue);
    }

    function getContent(script) {
        if (script) {
            return (
                <AceEditor
                    mode="java"
                    theme="tomorrow"
                    onChange={onChange}
                    name={script.code}
                    editorProps={{ $blockScrolling: true }}
                    value={script.decodedcontent}
                    width="100%"
                />
            )
        }
    }
    return (
        <div hidden={data === undefined}>
            <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
                onClick={(e) => {
                    setItemVisiblity((v) => !v);
                }} />
            <Badge bg="secondary"  >Function</Badge>
            <Badge className="ms-2" bg="info">{data['function-class-name']}</Badge>
            <BadgeExtended className="e-badge-name text-dark ms-2" bg="" tooltip="click to copy" copytext={getName()}>{data.name}</BadgeExtended>
            {getNumVariables(data.variables)}
            {getNumDependencies(data.dependenciesList)}
            <Collapse in={itemVisible}>
                <div className="ms-4">
                    <Variables data={data.variables} name={data.name} />
                    {getDependencies(data.dependenciesList)}
                    {getTransformerContent(data)}
                </div>
            </Collapse>
        </div>
    )
}
