import React, { useState, useRef, useEffect } from 'react';

import createEngine, { DefaultLabelFactory, DiagramModel, NodeModel } from '@projectstorm/react-diagrams';
import { DagreEngine } from './diagrams/dagre/DagreEngine.ts'
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import { Button, Modal, Form, Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

import { CustomNodeFactory } from './diagrams/customnode/node/CustomNodeFactory.tsx'
import { CustomPortFactory } from './diagrams/customnode/port/CustomPortFactory.tsx';
import { DefaultState } from './diagrams/state/DefaultState.ts';

import '../../diagram.css'
import { TunnelModel } from './diagrams/TunnelModel.ts';

import eventBus from '../../EventBus.js';

var engine;
var dagreEngine;
var model;
var config;
var needsToRegister = true;

export function Canvas() {
    const [showOptions, setShowOptions] = useState(false);
    const [showCanvasWidget, setShowCanvasWidget] = useState(false);
    const [gridSize, setModelGridsize] = useState(0);
    const handleClose = () => setShowOptions(false);
    const handleShow = () => setShowOptions(true);

    const componentRef = useRef();

    if (!engine) {
        engine = createEngine();
        const customNodeFactory = new CustomNodeFactory();
        engine.getNodeFactories().registerFactory(customNodeFactory);
        const portFactory = new CustomPortFactory();
        engine.getPortFactories().registerFactory(portFactory);
        const labelFactory = new DefaultLabelFactory();
        engine.getLabelFactories().registerFactory(labelFactory);
        engine.getStateMachine().pushState(new DefaultState());

        //https://github.com/dagrejs/dagre/wiki#configuring-the-layout
        model = new TunnelModel({ gridSize: 50 });
        // console.log(model)
        engine.setModel(model);


        dagreEngine = new DagreEngine({
            graph: {
                rankdir: 'LR', //Direction for rank nodes. Can be TB, BT, LR, or RL, where T = top, B = bottom, L = left, and R = right
                align: 'UR', //UL, UR, DL, or DR, where U = up, D = down, L = left, and R = right.
                ranker: 'longest-path', //network-simplex, tight-tree or longest-path
                marginx: 25,
                marginy: 25,
                compound: true,
                nodesep: 150,
                edgesep: 100,
                ranksep: 200,
                width: 1000,
                height: 1000
            },
            includeLinks: false,
            nodeMargin: 100
        });
    }

    (function (console) {
        console.save = function (data, filename) {
            if (!data) {
                console.error('Console.save: No data')
                return;
            }
            // data.tunnel=[]
            if (!filename) filename = 'console.json'
            if (typeof data === "object") {
                data = JSON.stringify(data, getCircularReplacer(), 4)
            }
            var blob = new Blob([data], { type: 'text/json' }),
                e = document.createEvent('MouseEvents'),
                a = document.createElement('a')
            a.download = filename
            a.href = window.URL.createObjectURL(blob)
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
            e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            a.dispatchEvent(e)
        }

        const getCircularReplacer = () => {
            const seen = new WeakSet();
            return (key, value) => {
                if (typeof value === "object" && value !== null) {
                    if (seen.has(value)) {
                        return;
                    }
                    seen.add(value);
                }
                return value;
            };
        };
    })(console)

    //create the model from the updated config
    useEffect(() => {
        // console.log('useEffect');
        if (needsToRegister) {
            needsToRegister = false;
            eventBus.on('data-loaded', (data) => {
                loadConfig(data);
                eventBus.dispatch("render-flow-done", '');
            });
            eventBus.on('zoom-selected', (data) => {
                console.log(componentRef)


                // autoDistribute();
                // engine.repaintCanvas();
                // engine.zoomToFitSelectedNodes({ margin: 10, maxZoom: 100 });
            });

        }
    })

    function loadConfig(data) {
        config = data;
        if (config && config.newModel) {
            config.newModel = false;
            var tunnelModel = new TunnelModel(50, config);
            engine.setModel(tunnelModel);
            dagreEngine.redistribute(tunnelModel);
            model = tunnelModel;
            engine.repaintCanvas();
        }
    }

    // UI functions 

    function autoDistribute() {
        if (config) {
            dagreEngine.redistribute(model);
            engine.repaintCanvas();
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function setRanker(e) {
        dagreEngine.setRanker(e.target.value);
        autoDistribute();
    }
    function setLayoutDirection(e) {
        dagreEngine.setLayoutDir(e.target.value);
        autoDistribute();
    }
    function setAlign(e) {
        dagreEngine.setAlign(e.target.value);
        autoDistribute();
    }

    function setGridsize(e) {
        setModelGridsize(Number(e.target.value))
        model.options.gridSize = gridSize;
        dagreEngine.redistribute(model);
    }

    function toggleDetails() {
        // console.log(model)
        for (const code in model.activeNodeLayer.models) {
            const node = model.activeNodeLayer.models[code];
            const hide = node.options.hideDetails;
            // console.log(hide)
            node.options.hideDetails = !hide;
        }
        dagreEngine.redistribute(model);
        engine.zoomToFit()
        engine.repaintCanvas();
    }

    return (
        <>
            <div>
                <div className='float-container'>
                    {/* <Button className='m-2' size='sm' onClick={() => engine.zoomToFit()}>Zoom to fit</Button> */}
                    <Button className='m-2' size='sm' onClick={() => engine.zoomToFitSelectedNodes({ margin: 10, maxZoom: 100 })}>Zoom nodes</Button>
                    <Button className='m-2' size='sm' onClick={autoDistribute}>Re-distribute</Button>
                    <Button className='m-2' size='sm' onClick={handlePrint}>Print</Button>
                    <Button className='m-2' size='sm' variant="primary" onClick={handleShow}>Options</Button>
                </div>
                <CanvasWidget ref={componentRef} className="canvas overflow" engine={engine} />
            </div>


            <Modal show={showOptions} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup>
                            <Form.Label>Layout direction</Form.Label>
                            <Form.Select onChange={setLayoutDirection}>
                                {/* TB, BT, LR, or RL, where T = top, B = bottom, L = left, and R = right */}
                                <option value='LR'>Left to Right</option>
                                <option value='RL'>Right to Left</option>
                                <option value='TB'>Top to Bottom</option>
                                <option value='TB'>Bottom to Top</option>
                            </Form.Select>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Ranker</Form.Label>
                            <Form.Select onChange={setRanker}>
                                <option value="network-simplex">network-simplex</option>
                                <option value="tight-tree">tight-tree</option>
                                <option value="longest-path">longest-path</option>
                            </Form.Select>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Align</Form.Label>
                            <Form.Select onChange={setAlign}>
                                {/* align: 'UL', //UL, UR, DL, or DR, where U = up, D = down, L = left, and R = right. */}
                                <option value='UL'>Up Left</option>
                                <option value='UR'>Up Right</option>
                                <option value='DL'>Down Left</option>
                                <option value='DR'>Down Right</option>
                            </Form.Select>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => engine.zoomToFit()}>Zoom to fit</Button>
                    <Button onClick={autoDistribute}>Re-distribute</Button>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}