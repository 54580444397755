import * as React from 'react';
import * as _ from 'lodash';
import { BaseNodeComponent } from './BaseNodeComponent.tsx';
import eventBus from '../../../../../EventBus.js';

export class ExitpointNodeWidget extends BaseNodeComponent {

	componentDidMount() {
		if (this.needsToRegister) {
			this.needsToRegister = false;
			eventBus.on("exitpoint-selected", (data) => {
				// console.log(this.node)
				if (data.code === this.dataNode.code) {
					this.node.setHideDetails(!this.dataNode.selected);
					this.setSelected(this.dataNode.selected);
					this.forceUpdate();
				}
			});
		}
	}

	toggleEntrypointDetails(node) {
		this.toggleDetails(node);
		eventBus.dispatch('diagramexitpoint-selected', { code: this.dataNode.code, selected: !node.needsToHide() });
	}
	render() {
		return (
			<div className='node-widget' style={{ background: this.props.node.getColor() }}>
				<div className='title'>
					<div className='title-name'>{this.props.node.getName()}</div>
					<button className='m-2 detail-button sm' onClick={() => this.toggleEntrypointDetails(this.props.node)}>details</button>
				</div>
				<div className='ports'>
					<div className='ports-container'>{_.map(this.props.node.getInPorts(), this.generatePort)}</div>
					<div className='ports-container'>{_.map(this.props.node.getOutPorts(), this.generatePort)}</div>
				</div>
				<div hidden={this.props.node.needsToHide()}>
					<table>
						<tbody>
							<tr><td>Url</td><td>{this.props.node.getUserData().fullUrl}</td></tr>
							<tr><td>Mofifiable</td><td><input type='checkbox' disabled checked={Boolean(this.props.node.getUserData().modifiable)} /></td></tr>
							<tr><td>Enabled</td><td><input type='checkbox' disabled checked={Boolean(this.props.node.getUserData().enabled)} /></td></tr>
							<tr><td>Token-auth</td><td><input type='checkbox' disabled checked={Boolean(this.props.node.getUserData()['token-auth'])} /></td></tr>
						</tbody>
					</table>

					<div className='variables' hidden={!this.hasArrayData(this.props.node.getUserData().config_parameters)}>
						<table>
							<tbody>
								<tr><th colSpan={2}>TRANSPORT PARAMETERS</th></tr>
								{this.getArrayData(this.props.node.getUserData().transport_parameters).map((variable) => (
									<tr key={variable.name}><td>{variable.name}</td><td>{variable.value.toString()}</td></tr>
								))}
							</tbody>
						</table>
					</div>

					<div className='config-parameters' hidden={!this.hasArrayData(this.props.node.getUserData().config_parameters)}>
						<table>
							<tbody>
								<tr><th colSpan={2}>CONFIG PARAMETERS</th></tr>
								{this.getArrayData(this.props.node.getUserData().config_parameters).map((parameter) => (
									<tr key={parameter.name}><td>{parameter.name}</td><td>{parameter.value.toString()}</td><td>{parameter.modifiable}</td></tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
