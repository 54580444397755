import React from 'react'
import { Badge } from 'react-bootstrap'

function ExportDetails({ configuration }) {
    function getVersion() {
        if (configuration === undefined)
            return ""
        return configuration.version
    }
    function getExportDate() {
        if (configuration === undefined)
            return ""
        return configuration.exportDate
    }

    function getOrganisations() {
        if (configuration === undefined)
            return ""
        if(configuration.organisations !== undefined)
            return configuration.organisations.organisation.name
        return ""
    }

    return (
        <div hidden={configuration === undefined}>
            {/* <Card > */}
            <div className="nowrap mt-4 mb-4">
                <Badge>Organisation: {getOrganisations()}</Badge>
                <Badge bg="secondary" className="ms-2">version: {getVersion()}</Badge>
                <Badge bg="secondary" className="ms-2">exportdate: {getExportDate()}</Badge>
            </div>
            {/* </Card> */}
        </div>
    )
}

export default ExportDetails


