import isIterable from "./helper";

/**
 * mappings have changed. was single key, now we have multi keys
 * so first check if we have multi or single
 * then we create a new List with the key value pairs
 * if we have multiple sourcekeys we add multiple rows for every sourcekey
 * @param {*} mapping 
 */
function fixMapping(configuration, mapping) {
    const valueList = [];
    if (mapping.values) {
        if (isIterable(mapping.values.value)) {
            //iterate the values
            for (const value of mapping.values.value) {
                //there are two way for mapping values with an array of sourcekeys or a single source key
                var targetValue = {};
                if (value['source-keys']) {
                    //iterate the sourcekeys
                    if (isIterable(value['source-keys']['source-key'])) {
                        //add a new row for every source-key found
                        for (const key in value['source-keys']['source-key']) {
                            targetValue = { "key": key.key, "value": value['target-key'] };
                        }
                    } else {
                        targetValue = { "key": value['source-keys']['source-key'].key, "value": value['target-key'] };
                    }
                } else {
                    targetValue = { "key": value['source-key'], "value": value['target-key'] };
                }
                 valueList.push(targetValue);
            }
        }
    }
    mapping.valueList = valueList;
}

/**
 * 
 * @param {*} configuration 
 */
function buildTransformationMappings(configuration) {
    const map = {};
    if (configuration.mappings) {
        const mappings = configuration.mappings['transformation-mapping'];
        if (isIterable(mappings)) {
            for (const tm of mappings) {
                fixMapping(configuration, tm);
                tm.code = tm._attributes.code;
                tm.type = 'mapping';
                map[tm.code] = tm;
            }
        } else {
            const tm = mappings;
            fixMapping(configuration, tm);
            tm.code = tm._attributes.code;
            tm.type = 'mapping';
            map[tm.code] = tm;
        }
    }

    return map
}

function buildMappingNames(configuration) {
    const map = {};
    if (configuration.mappings) {
        const mappings = configuration.mappings['transformation-mapping'];
        if (isIterable(mappings)) {
            for (const tm of mappings) {
                map[tm.name] = tm;
            }
        } else {
            const tm = mappings;
            map[tm.name] = tm;
        }
    }

    return map
}

function getMappingsArray(config) {
    const result = [];
    if (config !== undefined && config.mappingNames !== undefined) {
        //   console.log(config.mappingNames);
        for (let [key, value] of Object.entries(config.mappingNames)) {
            result.push(value);
        };
    }
    // console.log(result);
    return result;
}

export { buildTransformationMappings, buildMappingNames, getMappingsArray }