import * as React from 'react';
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams-core';
import { CustomPortModel } from './CustomPortModel.ts';
import styled from '@emotion/styled';

export interface CustomPortLabelProps {
	port: CustomPortModel;
	engine: DiagramEngine;
}

namespace S {
	export const Label = styled.div`
		padding: 0 5px;
		flex-grow: 1;
	`;

	export const Port = styled.div<{ options: any }>`
		background: ${(p) => (p.options.backgroundColor ? p.options.backgroundColor : 'rgba(255, 255, 255, 0.4)')};
		width: 20px;
		height: 16px;
		border-radius: ${(p) => (p.options.in ? '0px 8px 8px 0px' : '8px 0px 0px 8px')};
		margin-top: 2px;
		margin-bottom: 2px;

		&:hover {
			background: rgba(255, 100, 100, 1);
			border-width: 1px;
			border-color: rgb(255,100,0);
			border-style: solid;
		}
	`;
}

export class CustomPortLabel extends React.Component<CustomPortLabelProps> {

	getPortTargetName() {

		for (var i in this.props.port.links) {
			const link = this.props.port.links[i];
			if (!this.props.port.getOptions().in) {
				const targetNode = link.getTargetPort().getParent();
				return targetNode.getOptions().name;
			} else {
				const sourceNode = link.getSourcePort().getParent();
				return sourceNode.getOptions().name;
			}
		}

		return this.props.port.getOptions().name
	}

	render() {
		const port = (
			<PortWidget engine={this.props.engine} port={this.props.port}>
				<S.Port options={this.props.port.getOptions()} />
			</PortWidget>
		);
		const label = <div className='port-label-label'>{this.props.port.getOptions().label}</div>;

		return (
			<div className='port-label' title={this.getPortTargetName()}>
				{this.props.port.getOptions().in ? port : label}
				{this.props.port.getOptions().in ? label : port}
			</div>
		);
	}
}
