import React, { useState } from "react";
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Exitpoint from "./Exitpoint";

export default function AsyncExitpoint({ data }) {
  const [itemVisible, setItemVisiblity] = useState(false);

  return (
    <div className='mb-1'>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge bg="secondary" >Async Exitpoint</Badge>
      <div>  {itemVisible && (
        <div className='row  ms-2'>
          <Exitpoint data={data} />
        </div>
      )}
      </div>

    </div>
  )
}
