import React, { useState, useEffect } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import Tunnel from "./Tunnel";
import Tunnels from "./Tunnels";
import Variables from "./Variables";
import BadgeExtended from "../react/BadgeExtended";

import eventBus from "../../EventBus";

library.add(faCaretRight);


export default function Entrypoint({ data, expand, showtunnel }) {
  const [entrypointVisible, setEntrypointVisiblity] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  if (data === undefined) data = {};
  const showIcon = data.config_parameters.length > 0 || (data.tunnel !== undefined && showtunnel);

  //set all eventhandlers 
  useEffect(() => {
    if (isRegistered) {
      return;
    }
    eventBus.on('diagramentrypoint-selected', (newdata) => {
      if (newdata.code === data.code) {
        setEntrypointVisiblity(newdata.selected);
      }
    });
    setIsRegistered(true);
  });

  function getTunnel(entrypoint, showtunnel) {
    console.log(entrypoint.tunnels)
    if (showtunnel) {
      if (entrypoint !== undefined && entrypoint.tunnel !== undefined && entrypoint.tunnel.code !== undefined) {
        return <Tunnel data={entrypoint.tunnel} />
      }
    }
    return
  }

  function getTunnels(entrypoint, showtunnel) {
    // console.log(entrypoint.tunnels)
    if (showtunnel) {
      // if (entrypoint !== undefined && entrypoint.tunnel !== undefined && entrypoint.tunnel.code !== undefined) {
      return <Tunnels data={Object.values(entrypoint.tunnels)} />
      // }
    }
    return
  }

  function getVariables(name, variables) {
    if (variables !== undefined && variables.length > 0) {
      return <Badge className="ms-2">{variables.length} {name}</Badge>
    }
  }

  function getFullPath(data) {
    var path = data.protocol.toLowerCase();
    if (path !== 'invm') {
      path += '://' + data.host + ':' + data.port + data['context-path'];
    }
    return path;
  }

  function handleSelected() {
    setEntrypointVisiblity((v) => !v);
    data.selected = !entrypointVisible;
    // console.log(data);
    eventBus.dispatch('entrypoint-selected', data);
  }

  function getTunnelSpecs(tunnels) {
    if (tunnels) {
      var headerText = Object.values(tunnels).length + " Tunnels";
      var bodyText = "<b>List</b><ol> "
      Object.values(tunnels).map(tunnel => {
        bodyText += "<li>"+tunnel.name + "</li>";
      })
      bodyText += "</ol>"
      // if (data.invmEntryPoint.tunnel) {
      //   bodyText += "Tunnel: " + data.invmEntryPoint.tunnel.name;
      // }
      // console.log(data)
      return {
        header: headerText,
        body: bodyText
      }
    }
    return { header: "", body: "" }
  }

  return (
    <div>
      <FontAwesomeIcon
        icon={faCaretRight}
        className={`d-inline d-tree-toggler ${entrypointVisible && showIcon ? "active" : ""}`}
        onClick={handleSelected} />
      <Badge className="badge-90" bg="secondary" >Entrypoint</Badge>
      <Badge className="ms-2" bg="danger" hidden={data.enabled !== "false"}>Disabled</Badge>
      <BadgeExtended className="ms-2 badge-80" tooltip="get full path" copytext={getFullPath(data)}>{data.protocol}</BadgeExtended>
      <BadgeExtended className="ms-2 badge-120">{data['expected-mime-type']}</BadgeExtended>
      <BadgeExtended className="ms-2" tooltip="click to copy" action="copy">{data.host}</BadgeExtended>
      <BadgeExtended className={data.port !== '0' ? "ms-2 badge-40" : "me-2 visually-hidden"} tooltip="click to copy" action="copy">{data.port}</BadgeExtended>
      <BadgeExtended className={data.protocol === 'INVM' ? "ms-2 visually-hidden" : "ms-2"} tooltip="click to copy" action="copy">{data['context-path']}</BadgeExtended>
      {/* <div className="e-badge-name text-dark size_3 ms-2" >{data.name}</div> */}
      {getVariables("config", data['config_parameters'])}
      <BadgeExtended className='ms-2 bg-blue e-badge-tunnel'  bg="" popover={getTunnelSpecs(data.tunnels)}>{Object.values(data.tunnels).length} Tunnels</BadgeExtended>
      <BadgeExtended className="e-badge-name text-dark ms-2" bg="" data={data} tooltip="click to copy" action="copy">{data.name}</BadgeExtended>
      <Collapse in={entrypointVisible}>
        <div className="ms-4">
          <div>
            <div className="ms-4">
              <Variables data={data.config_parameters} type='Config Parameters' name={data.name} />
              <div>{getTunnels(data, showtunnel)}</div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
