import React, { useState, useRef, useEffect } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import * as _ from 'lodash';
import { BaseNodeComponent } from './BaseNodeComponent.tsx';
import eventBus from '../../../../../EventBus.js';

export class TunnelNodeWidget extends BaseNodeComponent {
	componentDidMount() {
		if (this.needsToRegister) {
			this.needsToRegister = false;
			// eventBus.on("tunnel-select",this.tunnelselected);
			eventBus.on("tunnel-select", (data) => {
				if (data.code === this.dataNode.code) {
					// console.log(this.node)
					this.node.setHideDetails(!this.dataNode.selected)
					this.setSelected(this.dataNode.selected);
					this.forceUpdate();
				}
			});
			eventBus.on('tunnel-color', (data) => {
				if (data.code === this.dataNode.code) {
					this.node.setColor(data.color);
					this.forceUpdate();
				}
			});
		}
	}

	toggleTunnelDetails(node) {
		this.toggleDetails(node);
		eventBus.dispatch('diagramtunnel-selected', { code: this.dataNode.code, selected: !node.needsToHide() });
	}

	render() {
		return (
			<div className='tunnel-widget tunnel-shadow' style={{ background: this.props.node.getColor() }}>
				<div className='title'>
					<div className='title-name'>Tunnel {this.props.node.getName()}</div>
					<button className='m-2 detail-button sm' onClick={() => this.toggleTunnelDetails(this.props.node)}>details</button>
				</div>
				<div className='description'>{this.props.node.getUserData().description}</div>
				<div className='ports'>
					<div className='ports-container'>{_.map(this.props.node.getInPorts(), this.generatePort)}</div>
					<div className='ports-container'>{_.map(this.props.node.getOutPorts(), this.generatePort)}</div>
				</div>
				<div hidden={this.props.node.needsToHide()}>
					<div className='common'>
						<strong>Common</strong>
						<table>
							<tbody>
								<tr><td>Asynchronous</td><td><input type='checkbox' disabled checked={(/true/).test(this.props.node.getUserData().common['res-async'])} /></td></tr>
								<tr><td>Mofifiable</td><td><input type='checkbox' disabled checked={(/true/).test(this.props.node.getUserData().modifiable)} /></td></tr>
								<tr><td>Collect procvars</td><td><input type='checkbox' disabled checked={(/true/).test(this.props.node.getUserData().common['collect-proc-vars'])} /></td></tr>
								<tr><td>Collect statistics</td><td><input type='checkbox' disabled checked={(/true/).test(this.props.node.getUserData().common['collect-stats'])} /></td></tr>
								<tr><td>Prevent duplicate messages</td><td><input type='checkbox' disabled checked={(/true/).test(this.props.node.getUserData().common['duplicate-elimination'])} /></td></tr>
								<tr><td>Message Encryption</td><td><input type='checkbox' disabled checked={(/true/).test(this.props.node.getUserData().common['encrypt-audit'])} /></td></tr>
								<tr><td>Retention (sec)</td><td><input value={this.props.node.getUserData().common['audit-retention']} disabled /></td></tr>
								<tr><td>Retention fault messages (sec)</td><td><input value={this.props.node.getUserData().common['audit-retention-fault']} disabled /></td></tr>
							</tbody>
						</table>
					</div>
					<div className='variables' hidden={!this.hasRuntimeVariables()}>
						<table>
							<tbody>
								<tr><th colSpan={2}>RUNTIMEVARIABLES</th></tr>
								<tr><th>name</th><th>value</th></tr>
								{this.getRuntimeVariables().map((variable, index) => (
									<tr key={variable.name}><td>{index}</td><td>{variable.name}</td><td>{variable.value.toString()}</td></tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
