import React, { useState } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Mapping from "./Mapping";

export default function Mappings({ data }) {
  const [itemVisible, setItemVisiblity] = useState(false);
  if (data === undefined)
    data = [];

  return (
    <div className='mb-1' hidden={!(data.length > 0)}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge className='badge-fixed' bg="secondary"
      >{data.length} Mapping</Badge>
      {data.map((mapping) => (
        <Collapse in={itemVisible} key={mapping.code} >
          <div className='row  ms-4'>
            <Mapping data={mapping} />
          </div>
        </Collapse>
      ))}
    </div>
  )
}
