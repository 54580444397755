import isIterable from "./helper";
import addVariables from './variables'

export function buildFunctionNames(configuration) {
    const map = {};
    if (configuration['functions']) {
        const functions = configuration['functions']['function'];
        if (isIterable(functions)) {
            for (const entry of functions) {
                map[entry.name] = entry
            }
        } else {
            map[functions.name] = functions;
        }
    }
    return map;
}

export function getFunctionByName(name, configuration) {
    Object.prototype[Symbol.iterator] = function* () {
        for (const [key, value] of Object.entries(this)) {
            yield { key, value }; // or [key, value]
        }
    };
    for (const { value: func } of configuration.functionsMap) {
        if (func.name === name) {
            return func;
        }
    }
    return undefined;
}

function addFunction(map, func, configuration) {
    // console.log('ADDFUNCTION '+func.name)
    if (func === undefined || func._attributes === undefined)
        return
    func.code = func._attributes.code;
    func.type = "function";
    func.script = configuration.scriptsMap[func['script-ref']]
    map[func.code] = func;

    func.class = func.script.script_type.toLowerCase();
    func.extension = "txt";
    if (func.class === "freemarker")
        func.extension = "ftl";
    else if (func.class === "xslt")
        func.extension = "xml";
    else if (func.class === "groovy") {
        func.extension = "java";
    }

    func.variables = [];
    if (func['attributes'] !== undefined)
        addVariables(configuration, func, func['attributes']['attribute']);
        
}

export default function buildFunctionMap(configuration) {
    const map = {};
    if (configuration['functions']) {
        const functions = configuration['functions']['function'];
        if (isIterable(functions)) {
            for (const entry of functions) {
                addFunction(map, entry, configuration);
            }
        } else {
            addFunction(map, functions, configuration);
        }
    }
    return map;
}