export function getItemLength(item) {
    if (item !== undefined) {
        return item.length
    }
    return 0
}

export function getItemArray(item) {
    if (item !== undefined) {
        return item.map
    }
    return []
}

/**
 * Check if object/array is iterable
 * @param obj
 * @returns {boolean}
 */
export default function isIterable(obj) {
    if (obj === null || obj === undefined) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}


export function copyToClipboard(id) {
    // console.log("copy to clipboard")
    // Get the text field
    var copyText = document.getElementById(id);
  
    // Select the text field
    if(copyText){
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
    
        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
        // console.log(copyText.value)
    }
  }