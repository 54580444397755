import React, { useState, useEffect } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import Entrypoint from "./Entrypoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Variables from "./Variables";
import FileTunnel from "./FileTunnel";
import BadgeExtended from "../react/BadgeExtended";

import eventBus from "../../EventBus";

export default function Exitpoint({ data, type }) {
  const [exitpointVisible, setExitpointVisiblity] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  if (type === undefined)
    type = "Exitpoint"

  //set all eventhandlers 
  useEffect(() => {
    if (isRegistered) {
      return;
    }
    eventBus.on('diagramexitpoint-selected', (newdata) => {
      if (newdata.code === data.code) {
        setExitpointVisiblity(newdata.selected);
      }
    });
    setIsRegistered(true);
  });

  function getINVMEntrypoint(entrypoint) {
    if (entrypoint !== undefined)
      return <div className="ms-4"><Entrypoint data={data.invmEntryPoint} expand={true} showtunnel={true} /></div>
  }

  function getVariables(name, variables) {
    if (variables !== undefined && variables.length > 0) {
      return <Badge className="ms-2">{variables.length} {name}</Badge>
    }
  }

  function getFiletunnel(filetunnel) {
    if (filetunnel) {
      return <div className="ms-4"><FileTunnel data={filetunnel} /></div>
    }
  }

  function getFullPath(data) {
    var path = data.protocol.toLowerCase();
    if (path !== 'invm') {
      path += '://' + data.host + ':' + data.port + data.contextPath;
    }
    return path;
  }

  function getSpecs(data) {
    if (data.invmEntryPoint) {
      var headerText = data.name;
      var bodyText = "Entrypoint: " + data.invmEntryPoint.name;
      if (data.invmEntryPoint.tunnel) {
        bodyText += "Tunnel: " + data.invmEntryPoint.tunnel.name;
      }
      // console.log(data)
      return {
        header: headerText,
        body: bodyText
      }
    }
    return { header: "", body: "" }
  }

  function getName(data) {
    if (data.invmEntryPoint) {
      return data.invmEntryPoint.name;
    }
    return data.name;
  }

  function handleSelected() {
    setExitpointVisiblity((v) => !v);
    data.selected = !exitpointVisible;
    // console.log(data);
    eventBus.dispatch('exitpoint-selected', data);
  }

  return (
    <div className='nowrap'>
      <FontAwesomeIcon
        icon={faCaretRight}
        className={`d-inline d-tree-toggler ${exitpointVisible ? "active" : ""}`}
        onClick={handleSelected} />
      <Badge className={type === 'Exitpoint' ? "badge-90" : "badge-120"} bg="secondary" >{type}</Badge>
      <BadgeExtended className="ms-2 badge-80" tooltip="copy full path" copytext={getFullPath(data)}>{data.protocol}</BadgeExtended>
      <BadgeExtended className="ms-2" bg="secondary" hidden={data.invmEntryPoint === undefined} copytext={getName(data)} popover={getSpecs(data)}>connects to Entrypoint</BadgeExtended>
      <BadgeExtended className="ms-2" tooltip="click to copy" action="copy">{data.host}</BadgeExtended>
      <BadgeExtended className={data.port !== '0' ? "ms-2" : "me-2 visually-hidden"} tooltip="click to copy" action="copy">{data.port}</BadgeExtended>
      <Badge className="ms-2" bg='warning' hidden={!data.selectorFiletunnel}>FileTunnel</Badge>
      <BadgeExtended className={data.protocol === 'INVM' ? "ms-2 visually-hidden" : "ms-2"} tooltip="click to copy" action="copy">{data.contextPath}</BadgeExtended>
      {/* <div className="e-badge-name text-dark size_3 ms-2">{data.name}</div> */}
      {getVariables("config", data['config_parameters'])}
      {getVariables("transport", data['transport_parameters'])}

      <BadgeExtended className="e-badge-name text-dark ms-2" bg="" data={data} tooltip="click to copy" copytext={data.name}>{data.name}</BadgeExtended>
      <Collapse in={exitpointVisible}>
        <div className="ms-4">
          {/* {exitpointVisible && ( */}
          <div>
            <div className="ms-4"><Variables data={data.config_parameters} type="Config Parameters" name={data.name} /></div>
            <div className="ms-4"><Variables data={data.transport_parameters} type="Transport Parameters" name={data.name} /></div>
            {getINVMEntrypoint(data.invmEntryPoint)}
            {getFiletunnel(data.filetunnel)}
          </div>
          {/* )} */}
        </div>
      </Collapse>
    </div>
  )
}
