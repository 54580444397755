import isIterable from './helper';

function addScript(map, script) {
    if (script === undefined || script._attributes === undefined)
        return
    script.code = script._attributes.code;
    script.name = script['script-name'];
    script.script_type = script['script-type'];
    script.type = "script";
    var base64 = require('base-64');
    script.decodedcontent = base64.decode(script.content)
    map[script._attributes.code] = script;
}

export default function buildScriptsMap(configuration) {
    const map = {};
    if (configuration["scripts"]) {
        const scripts = configuration["scripts"]["script"];
        if (isIterable(scripts)) {
            for (const entry of scripts) {
                addScript(map, entry);
            }
        } else {
            addScript(map, scripts);
        }
    }
    return map;
}