import isIterable from "./helper";

function buildEntryPointMap(configuration) {
    const map = {};
    const entryPoints = configuration["entrypoints"]["entrypoint"];
    if (isIterable(entryPoints)) {
        for (const entry of entryPoints) {
            addEntryPoint(map, entry, configuration);
        }
    } else {
        addEntryPoint(map, entryPoints, configuration);
    }
    return map;
}

function addEntryPoint(map, entrypoint, configuration) {
    entrypoint.code = entrypoint._attributes.code;
    entrypoint.type = "entrypoint";
    addFullUrl(entrypoint);
    entrypoint.tunnels = {};
    EventSource.icon = "fa fa-folder";
    map[entrypoint._attributes.code] = entrypoint;

    const config_parameters = [];
    if (entrypoint.properties) {
        if (isIterable(entrypoint.properties.property)) {
            for (const prop of entrypoint.properties.property) {
                // console.log(prop);
                config_parameters.push(prop);
            }
        } else {
            // console.log(entrypoint.properties.property);
            config_parameters.push(entrypoint.properties.property);
        }
    }
    entrypoint.config_parameters = config_parameters;
}

function addFullUrl(entrypoint) {
    let fullUrl = entrypoint.protocol.toLowerCase();
    const regex = /esb|invm|interval/;
    if (!regex.test(entrypoint.protocol.toLowerCase())) {
        entrypoint.host??='';
        entrypoint["context-path"]??='';
        fullUrl += "://" + entrypoint.host + ":" + entrypoint.port + entrypoint["context-path"];
    }
    entrypoint.fullUrl = fullUrl;
}

/**
 * Find entry points for tunnel
 * @param tunnel
 * @param configuration
 * @returns {{}}
 */
function findEntryPoints(tunnel, configuration) {
    const entryCodes = [];
    const entryPoints = tunnel.common['entry-points']['entry-point'];
    // console.log("findEntryPoints 1 ")
    if (entryPoints[0] !== undefined) {
        for (const entry of entryPoints) {
            if (entry['entry-point-ref'] !== undefined) {
                entryCodes.push(entry['entry-point-ref']);
            }
        }
    } else {
        entryCodes.push(entryPoints['entry-point-ref']);
    }

    const data = [];
    for (const code of entryCodes) {
        const entrypoint = configuration.entrypointmap[code];
        data.push(entrypoint);
        entrypoint.tunnels[tunnel.code] = tunnel;
        entrypoint.tunnel = tunnel;
    }

    return data;
}

function getEntrypointsArray(config) {
    const result = [];
    if (config !== undefined && config.entrypointmap !== undefined) {
      const arr = Array.from(config.entrypointmap)
        for (let [key, value] of Object.entries(config.entrypointmap)) {
        result.push(value);
      };
    }
    return result;

  }

export { buildEntryPointMap, findEntryPoints, getEntrypointsArray };