import isIterable from "./helper";

/**
 * 
 * @param {*} map 
 * @param {*} profile 
 * @param {*} type 
 * @param {*} configuration 
 */
function addProfile(map, profile, type, configuration) {
    profile.code = profile._attributes.code;
    profile.type = type;
    profile.transformers = [];
    if (profile['profile-templates']) {
        const templates = profile['profile-templates']['profile-template'];
        if (isIterable(templates)) {
            for (const ref_template of templates) {
                const transformer = {};
                transformer.template = configuration.messageTransformerMap[ref_template['messagetransformer-ref']];
                if (transformer.template !== undefined && ref_template.expression !== undefined && ref_template.expression.length > 0) {
                    transformer.conditional = ref_template.expression + ' == ' + ref_template['expr-value'];
                }
                profile.transformers.push(transformer);
            }
        } else {
            const transformer = {};
            transformer.template = configuration.messageTransformerMap[templates['messagetransformer-ref']];
            if (transformer.template !== undefined && templates.expression !== undefined && templates.expression.length > 0) {
                transformer.conditional = templates.expression + ' == ' + templates['expr-value'];
            }
            profile.transformers.push(transformer);
        }
    }
    map[profile.code] = profile;
}

/**
 * get build messageProfile map.
 * version 2.x and 1.7
 * @param configuration
 * @returns {{}}
 */
function buildOutgoingProfilesMap(configuration) {
    const map = {};

    if (configuration['outgoing-profiles']) {
        const ogp = configuration['outgoing-profiles']['outgoing-profile'];
        if (isIterable(ogp)) {
            for (const op of ogp) {
                addProfile(map, op, "profile", configuration);
            }
        } else {
            addProfile(map, ogp, "profile", configuration);
        }
    }
    return map;
}

export { buildOutgoingProfilesMap }