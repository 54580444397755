
function getArrayFromHash(map) {
    var values = [];
    for (var key in map) {
      values.push(map[key]);
    }
    return values;
  }

  function hasVar(variables, searchText) {
    // console.log(searchText)
    // console.log(variables);
    for (var i in variables) {
      try {
        if (variables[i].name.toLowerCase().indexOf(searchText) !== -1) {
          return true;
        }
      } catch {
      }
    }
    return false;
  }

  export { getArrayFromHash , hasVar}