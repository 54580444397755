import addVariables from './variables'
import isIterable from './helper';

/**
 *  * transformers may have dependenciees
 * The dependency has a name and one of the folowing types
 * - transformation-mapping-ref
 * - outgoing-profile-ref
 * - exit-point-ref
 * - function-ref
 * 
 * @param {*} map 
 * @param {*} mt 
 */
function addMessagetransformer(map, mt, configuration) {
    // console.log(mt.name)
    mt.code = mt._attributes.code;
    mt.type = 'transformer';
    const index = mt.classname.lastIndexOf('.');
    const indexend = mt.classname.lastIndexOf('Transformer');
    mt.class = mt.classname.toLowerCase().substring(index + 1, indexend);
    mt.extension = "txt";
    if (mt.class === "freemarker")
        mt.extension = "ftl";
    else if (mt.class === "xslt")
        mt.extension = "xml";
    else if (mt.class === "groovy") {
        mt.extension = "java";
        // console.log(mt)
    }

    mt['target-contenttype'] = '';

    mt.variables = [];
    if (mt['template-attributes'] !== undefined)
        addVariables(configuration, mt, mt['template-attributes']['template-attribute']);

    mt.copyright = mt.copyrighted === "true";
    if (mt.content !== undefined) {
        var base64 = require('base-64');
        mt.decodedcontent = base64.decode(mt.content);
    } 
    // else {
    //     console.log(mt.code + "has no content");
    // }

    if( mt.description !== undefined  ){
        if(mt.description.length > 0)
            mt.descr = mt.description;
        else
            mt.descr = undefined;
    } else{
        mt.descr = undefined
    }

    map[mt.code] = mt;
}

/**
 * build messageTranfrom Map
 * 
 * @param configuration
 * @returns {{}}
 */
export default function buildMessagetransformerMap(configuration) {
    const map = {};
    if (configuration['message-transformers']) {
        const messageTransformers = configuration['message-transformers']['message-transformer'];

        if (isIterable(messageTransformers)) {
            for (const mt of messageTransformers) {
                addMessagetransformer(map, mt, configuration);
            }
        } else {
            addMessagetransformer(map, messageTransformers, configuration);
        }
    }
    return map;
}