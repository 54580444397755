import React, { useState, useRef } from "react";
import ReactDOM from 'react-dom';
import { Badge, Collapse, FormControl, Overlay } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";

export default function Namespaces({ data, name }) {
  const [varVisible, setVarVisiblity] = useState(false);
  const [nsSearchText, setNsSearchText] = useState([]);
  const target = useRef(null);
  var containerRef = useRef(null);
  if (data === undefined) data = [];

  function handleSaveResultClick() {
    const result = []
    data.filter(namespace => namespace.uri.toLowerCase().indexOf(nsSearchText) !== -1).map((namespace) => (
      result.push('xmlns://' + namespace._attributes.code + '=' + namespace.uri.replace('"', ''))
    ))
    var blob = new Blob([result.join("\n")], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, name + ".tunnelvars");
  }

  return (
    <div className='mb-1' hidden={data.length === 0}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${varVisible ? "active" : ""}`}
        onClick={(e) => {
          setVarVisiblity((v) => !v);
        }} />
      <Badge className='badge-fixed' bg="secondary" >{data.length} Namespaces</Badge><Badge
        className="ms-2"
        variant="success"
        onClick={(e) => {
          handleSaveResultClick();
        }}>
        Export
      </Badge>
      <Collapse in={varVisible} >
        <div className="ms-4">
          <div className="variable-table mt-2" >
            <table className="mt-2" style={{ fontSize: "14px" }}>
              <thead>
                <tr><td colSpan="2">
                  <div>
                    <input autoFocus className="form-control"
                      value={nsSearchText}
                      type="text"
                      onChange={e => setNsSearchText(e.target.value)} id="nsSearchId" />
                  </div>
                </td>
                </tr>
                <tr>
                  <th>name</th>
                  <th>value</th>
                </tr>
              </thead>
              <tbody>
                {data.filter(namespace => namespace.uri.toLowerCase().indexOf(nsSearchText) !== -1).map((namespace) => (
                  <tr key={namespace._attributes.code}>
                    <td>{namespace._attributes.code}</td>
                    <td>
                      <div>{namespace.uri}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Collapse>
    </div>
  )
}
