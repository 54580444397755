import React, { useState, useEffect } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import Function from "./Function";
import Mapping from "./Mapping";
import Exitpoint from "./Exitpoint";

export default function Variables({ data, name, type }) {
  const [varVisible, setVarVisiblity] = useState(false);
  const [visibleVars, setVisibleVars] = useState([]);
  const [searchText, setSearchText] = useState([])
  if (data === undefined) data = [];
  if (name === undefined) name = 'variables';
  if (type === undefined) type = 'variables';
  if(!Array.isArray(data)) data = [];

  useEffect(() => {
    if (varVisible) {
      setVisibleVars(data)
    } 
  }, [varVisible, data]);

  function handleSaveResultClick() {
    const result = []
    data.filter(variable => variable.name.toLowerCase().indexOf(searchText) !== -1).map((variable) => (
      result.push(variable.name + '=' + variable.value)
    ))
    var blob = new Blob([result.join("\n")], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, name + ".tunnelvars");
  }

  function getElements(val) {
    // console.log("GET ELEMENTS")
    const regex = /(^\/\/|xpath:\/\/|const:\/\/|function:\/\/)(.+?)(?=^\/\/|xpath:\/\/|const:\/\/|function:\/\/|$)/gi;
    const found = val.match(regex);
    // console.log(found);
    if (found !== null)
      return found;
    return [];
  }

  function getObject(rtv) {
    if (rtv.function) {
      return <Function data={rtv.function} />
    } else if (rtv.mapping) {
      return <Mapping data={rtv.mapping} />
    } else if (rtv.exitpoint) {
      return <Exitpoint data={rtv.exitpoint} type="ScriptExitpoint" />
    }
  }

  function varInfo(variable) {
    // if(variable.name === 'copyMessageHeaders'){
    //   return 'add_red';
    // }
    return '';
  }

  return (
    <div className='mb-1' hidden={data.length === 0}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${varVisible ? "active" : ""}`}
        onClick={(e) => {
          setVarVisiblity((v) => !v);
        }} />
      <Badge className='badge-120' bg="secondary" >{data.length} {type}</Badge>
      <Badge
        className="ms-2 badge-80"
        bg="info"
        onClick={(e) => {
          handleSaveResultClick();
        }}>
        Export
      </Badge>
      <Collapse in={varVisible} >
        <div className="ms-4 variable" >
          <div className="variable-table mt-2"                                                                                                                                                                  >
            <table className="mt-2" style={{ fontSize: "14px" }}>
              <thead>
                <tr className="bg-white" >
                  <th className="bg-white" colSpan={2}>
                    {/* <FormControl
                      autoFocus
                        id="search-mapping"
                        type="text"
                        placeholder="Search"
                        hidden={data.length < 5}
                        onChange={e => setSearchText(e.target.value.toLowerCase())}
                      /> */}
                    <input autoFocus
                      id="search-mapping"
                      className="form-control"
                      value={searchText}
                      type="text"
                      hidden={data.length < 5}
                      onChange={e => setSearchText(e.target.value)} />
                  </th>
                </tr>
                <tr>
                  <th>name</th>
                  <th>value</th>
                </tr>
              </thead>
              <tbody>
                {visibleVars.filter(variable => variable.name.toLowerCase().indexOf(searchText) !== -1).map((variable) => (
                  <tr key={variable.name + variable.value}>
                    <td className={varInfo(variable)}>{variable.name}</td>
                    <td>{variable.value}
                      <div>{getObject(variable)}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Collapse>
    </div>
  )
}
